import { Group, Object3D } from 'three';
import * as common from './common.js';
import { MeshName } from '../../shared/Enums.js';

/**
 * Various external (common) parts of the Sully 1800 (left) range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
function parts(meshes) {
  // Left Cupboard Door
  const leftCupboardDoor = meshes
    .getObjectByName(MeshName.cupboardDoor)
    .clone();
  leftCupboardDoor.name = MeshName.leftCupboardDoor;
  leftCupboardDoor.translateX(0.2);

  // Left Cupboard Vent Cover
  const leftCupboardVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  leftCupboardVentCover.name = MeshName.leftCupboardVentCover;
  leftCupboardVentCover.translateX(0.2);

  // Mini Oven Vent Cover
  const leftOvenMiniVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  leftOvenMiniVentCover.name = MeshName.leftOvenMiniVentCover;
  leftOvenMiniVentCover.position.x = -0.292;
  leftOvenMiniVentCover.visible = false;
  const rightOvenMiniVentCover = leftOvenMiniVentCover.clone();
  rightOvenMiniVentCover.name = MeshName.rightOvenMiniVentCover;
  rightOvenMiniVentCover.position.x = 0.696;
  rightOvenMiniVentCover.visible = false;

  return [
    leftCupboardDoor,
    leftCupboardVentCover,
    leftOvenMiniVentCover,
    rightOvenMiniVentCover,
    ...common.sullyOvenDoorsAndDrawers(meshes, 0.2),
  ];
}

/**
 * All the classique trim parts for a Sully 1800 (left) range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.translateX(0.2);
  const towelBarSupportMidLeft = meshes
    .getObjectByName(MeshName.towelBarMidLeftSupport)
    .clone();
  towelBarSupportMidLeft.translateX(0.2);
  const towelBarSupportCenter = meshes
    .getObjectByName(MeshName.towelBarCenterSupport)
    .clone();
  towelBarSupportCenter.translateX(-0.2);
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.translateX(0.2);

  // Left Cupboard Door Bar Supports
  const leftCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  leftCupboardDoorBarSupportLeft.name = MeshName.leftCupboardBarLeftSupport;
  leftCupboardDoorBarSupportLeft.position.x = -0.814;
  const leftCupboardDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  leftCupboardDoorBarSupportRight.name = MeshName.leftCupboardBarRightSupport;
  leftCupboardDoorBarSupportRight.position.x = -0.59;

  // Cupboard Door Bars
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  leftCupboardDoorBar.translateX(0.2);

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportMidLeft,
    towelBarSupportCenter,
    towelBarSupportRight,
    leftCupboardDoorBarSupportLeft,
    leftCupboardDoorBarSupportRight,
    leftCupboardDoorBar,
    ...common.sullyOvenClassiqueTrim(meshes, 0.2)
  );
}

/**
 * All the moderne trim parts for a Sully 1800 (left) range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function moderneTrim(meshes) {
  const trim = new Group();

  // Left Cupboard Bar Supports
  const leftCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  leftCupboardDoorBarSupportLeft.position.x = -0.82;
  const leftCupboardDoorBarSupportRight =
    leftCupboardDoorBarSupportLeft.clone();
  leftCupboardDoorBarSupportRight.position.x = -0.58;

  // Left Cupboard Door Bar
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  leftCupboardDoorBar.translateX(0.2);

  return trim.add(
    leftCupboardDoorBarSupportLeft,
    leftCupboardDoorBarSupportRight,
    leftCupboardDoorBar,
    ...common.sullyOvenModerneTrim(meshes, 0.2)
  );
}

export { parts, classiqueTrim, moderneTrim };
