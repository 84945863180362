import { Group } from 'three';
import { AssetLoader } from './AssetLoader.js';
import { Materials } from './Materials.js';

class SharedParts {
  logo = new Group();

  #assets;
  #materials;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   */
  constructor(assets, materials) {
    this.#assets = assets;
    this.#materials = materials;
    this.#loadModels();
    this.#applyMaterials();
    this.logo.name = 'Lacanche_Logo';
  }

  #loadModels() {
    // Lacanche Logo
    while (this.#assets.logoData.scene.children.length) {
      this.logo.add(this.#assets.logoData.scene.children[0]);
    }
  }

  #applyMaterials() {
    this.#materials.applyBrassMaterial(
      this.logo.getObjectByName('Logo_Border')
    );

    this.#materials.applyChromeMaterial(
      this.logo.getObjectByName('Logo_Screws')
    );

    this.#materials.applyLacancheLogoMaterial(
      this.logo.getObjectByName('Logo_Plate')
    );
  }
}

export { SharedParts };
