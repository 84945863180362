import { Group, Object3D } from 'three';

import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Chagny range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
function parts(meshes) {
  // Regular Oven Doors
  const rightOvenDoor = meshes.getObjectByName(MeshName.clunyOvenDoor).clone();
  rightOvenDoor.name = MeshName.rightOvenDoor;
  rightOvenDoor.position.x = 0;
  const leftOvenDoor = rightOvenDoor.clone();
  leftOvenDoor.name = MeshName.leftOvenDoor;
  leftOvenDoor.position.x = -0.502;
  leftOvenDoor.translateY(-0.22);

  // Storage Drawer
  const storageDrawer = meshes
    .getObjectByName(MeshName.clunyStorageDrawer)
    .clone();
  storageDrawer.name = MeshName.storageDrawer;
  storageDrawer.position.x = 0;

  // Petite Oven Door
  const petiteOvenDoor = storageDrawer.clone();
  petiteOvenDoor.name = MeshName.petiteOvenDoor;
  petiteOvenDoor.position.x = -0.502;
  petiteOvenDoor.translateY(0.39);

  // Oven Vent Covers
  const leftOvenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCover)
    .clone();
  leftOvenVentCover.position.x = -0.215;
  const rightOvenVentCover = leftOvenVentCover.clone();
  rightOvenVentCover.position.x = 0.215;

  return [
    rightOvenDoor,
    leftOvenDoor,
    storageDrawer,
    petiteOvenDoor,
    leftOvenVentCover,
    rightOvenVentCover,
  ];
}

/**
 * All the classique trim parts for a Chagny range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName('Towel_Bar_Support_Left')
    .clone();
  towelBarSupportLeft.position.x = -0.46;
  const towelBarSupportRight = meshes
    .getObjectByName('Towel_Bar_Support_Right')
    .clone();
  towelBarSupportRight.position.x = 0.46;

  // Petite Oven Door Bar Supports
  const petiteOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  petiteOvenDoorBarSupportLeft.name = MeshName.petiteOvenDoorBarLeftSupport;
  petiteOvenDoorBarSupportLeft.position.x = -0.46;
  const petiteOvenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  petiteOvenDoorBarSupportRight.name = MeshName.petiteOvenDoorBarRightSupport;
  petiteOvenDoorBarSupportRight.position.x = -0.042;

  // Left Oven Door Bar Supports
  const leftOvenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  leftOvenDoorBarSupportLeft.name = MeshName.leftOvenDoorBarLeftSupport;
  leftOvenDoorBarSupportLeft.position.y += -0.22;
  const leftOvenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  leftOvenDoorBarSupportRight.name = MeshName.leftOvenDoorBarRightSupport;
  leftOvenDoorBarSupportRight.position.y += -0.22;

  // Right Oven Door Bar Supports
  const rightOvenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportLeft.name = MeshName.rightOvenDoorBarLeftSupport;
  rightOvenDoorBarSupportLeft.position.x = 0.042;
  const rightOvenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportRight.name = MeshName.rightOvenDoorBarRightSupport;
  rightOvenDoorBarSupportRight.position.x = 0.46;

  // Oven Door Bars
  const petiteOvenDoorBar = meshes
    .getObjectByName(MeshName.classiqueClunyOvenDoorBar)
    .clone();
  petiteOvenDoorBar.position.x = -0.251;
  const leftOvenDoorBar = petiteOvenDoorBar.clone();
  leftOvenDoorBar.position.y += -0.22;
  const rightOvenDoorBar = petiteOvenDoorBar.clone();
  rightOvenDoorBar.position.x = 0.251;

  // Drawer Pulls
  const drawerPullLeft = meshes.getObjectByName(MeshName.drawerPull).clone();
  drawerPullLeft.name = MeshName.drawerLeftPull;
  drawerPullLeft.position.x = 0.06;
  const drawerPullRight = drawerPullLeft.clone();
  drawerPullRight.name = MeshName.drawerRightPull;
  drawerPullRight.position.x = 0.43;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportRight,
    petiteOvenDoorBarSupportLeft,
    petiteOvenDoorBarSupportRight,
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    petiteOvenDoorBar,
    rightOvenDoorBar,
    leftOvenDoorBar,
    drawerPullLeft,
    drawerPullRight
  );
}

/**
 * All the moderne trim parts for a Chagny range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function moderneTrim(meshes) {
  const trim = new Group();

  // Oven Door Bars
  const petiteOvenDoorBar = meshes
    .getObjectByName(MeshName.moderneClunyOvenDoorBar)
    .clone();
  petiteOvenDoorBar.position.x = -0.251;
  const leftOvenDoorBar = petiteOvenDoorBar.clone();
  leftOvenDoorBar.position.y += -0.22;
  const rightOvenDoorBar = petiteOvenDoorBar.clone();
  rightOvenDoorBar.position.x = 0.251;

  // Petite Oven Bar Supports
  const petiteOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  petiteOvenDoorBarSupportLeft.position.x = -0.465;
  const petiteOvenDoorBarSupportRight = petiteOvenDoorBarSupportLeft.clone();
  petiteOvenDoorBarSupportRight.position.x = -0.04;

  // Left Oven Bar Supports
  const leftOvenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  leftOvenDoorBarSupportLeft.position.y += -0.22;
  const leftOvenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  leftOvenDoorBarSupportRight.position.y += -0.22;

  // Right Oven Bar Supports
  const rightOvenDoorBarSupportLeft = petiteOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportLeft.position.x = 0.04;
  const rightOvenDoorBarSupportRight = petiteOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportRight.position.x = 0.465;

  // Storage Drawer Lip
  const drawerLip = meshes
    .getObjectByName(MeshName.moderneClunyDrawerBottomLip)
    .clone();

  return trim.add(
    petiteOvenDoorBar,
    rightOvenDoorBar,
    leftOvenDoorBar,
    petiteOvenDoorBarSupportLeft,
    petiteOvenDoorBarSupportRight,
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    drawerLip
  );
}

export { parts, classiqueTrim, moderneTrim };
