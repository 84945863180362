import { Group, Object3D } from 'three';

import * as beaune from './beaune.js';
import * as chagny from './chagny.js';
import * as chambertin from './chambertin.js';
import * as cluny1800 from './cluny1800.js';
import * as cormatin from './cormatin.js';
import * as fontenay from './fontenay.js';
import * as sully from './sully.js';
import * as sully2200 from './sully2200.js';
import * as sully1800left from './sully1800left.js';
import * as sully1800right from './sully1800right.js';
import * as volnay from './volnay.js';

import { AssetLoader } from '../../shared/AssetLoader';
import { MeshName } from '../../shared/Enums.js';
import { Materials } from '../../shared/Materials';

class RangeParts {
  /** All the common parts for any Lacanche range */
  #parts = new Group();

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   */
  constructor(assets, materials) {
    // Load 3D models
    while (assets.rangePartsData.scene.children.length) {
      this.#parts.add(assets.rangePartsData.scene.children[0]);
    }

    this.#applyMaterials(materials);
  }

  /**
   * Various external (common) parts of a Beaune range
   * @returns {Object3D[]}
   */
  beauneParts() {
    return beaune.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Beaune range
   * @returns {Group}
   */
  beauneClassiqueTrim() {
    return beaune.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Beaune range
   * @returns {Group}
   */
  beauneModerneTrim() {
    return beaune.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Chagny range
   * @returns {Object3D[]}
   */
  chagnyParts() {
    return chagny.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Chagny range
   * @returns {Group}
   */
  chagnyClassiqueTrim() {
    return chagny.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Chagny range
   * @returns {Group}
   */
  chagnyModerneTrim() {
    return chagny.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Chambertin range
   * @returns {Object3D[]}
   */
  chambertinParts() {
    return chambertin.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Chambertin range
   * @returns {Group}
   */
  chambertinClassiqueTrim() {
    return chambertin.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Chambertin range
   * @returns {Group}
   */
  chambertinModerneTrim() {
    return chambertin.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Cluny 1800 range
   * @returns {Object3D[]}
   */
  cluny1800Parts() {
    return cluny1800.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Cluny 1800 range
   * @returns {Group}
   */
  cluny1800ClassiqueTrim() {
    return cluny1800.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Cluny 1800 range
   * @returns {Group}
   */
  cluny1800ModerneTrim() {
    return cluny1800.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Cormatin range
   * @returns {Object3D[]}
   */
  cormatinParts() {
    return cormatin.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Cormatin range
   * @returns {Group}
   */
  cormatinClassiqueTrim() {
    return cormatin.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Cormatin range
   * @returns {Group}
   */
  cormatinModerneTrim() {
    return cormatin.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Fontenay range
   * @returns {Object3D[]}
   */
  fontenayParts() {
    return fontenay.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Fontenay range
   * @returns {Group}
   */
  fontenayClassiqueTrim() {
    return fontenay.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Fontenay range
   * @returns {Group}
   */
  fontenayModerneTrim() {
    return fontenay.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Sully range
   * @returns {Object3D[]}
   */
  sullyParts() {
    return sully.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Sully range
   * @returns {Group}
   */
  sullyClassiqueTrim() {
    return sully.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Sully range
   * @returns {Group}
   */
  sullyModerneTrim() {
    return sully.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Sully 1800 (left) range
   * @returns {Object3D[]}
   */
  sully1800LeftParts() {
    return sully1800left.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Sully 1800 (left) range
   * @returns {Group}
   */
  sully1800LeftClassiqueTrim() {
    return sully1800left.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Sully 1800 (left) range
   * @returns {Group}
   */
  sully1800LeftModerneTrim() {
    return sully1800left.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Sully 1800 (right) range
   * @returns {Object3D[]}
   */
  sully1800RightParts() {
    return sully1800right.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Sully 1800 (right) range
   * @returns {Group}
   */
  sully1800RightClassiqueTrim() {
    return sully1800right.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Sully 1800 (right) range
   * @returns {Group}
   */
  sully1800RightModerneTrim() {
    return sully1800right.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Sully 2200 range
   * @returns {Object3D[]}
   */
  sully2200Parts() {
    return sully2200.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Sully 2200 range
   * @returns {Group}
   */
  sully2200ClassiqueTrim() {
    return sully2200.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Sully 2200 range
   * @returns {Group}
   */
  sully2200ModerneTrim() {
    return sully2200.moderneTrim(this.#parts);
  }

  /**
   * Various external (common) parts of a Volnay range
   * @returns {Object3D[]}
   */
  volnayParts() {
    return volnay.parts(this.#parts);
  }

  /**
   * All the classique trim parts for a Volnay range
   * @returns {Group}
   */
  volnayClassiqueTrim() {
    return volnay.classiqueTrim(this.#parts);
  }

  /**
   * All the moderne trim parts for a Volnay range
   * @returns {Group}
   */
  volnayModerneTrim() {
    return volnay.moderneTrim(this.#parts);
  }

  /** @param {Materials} materials */
  #applyMaterials(materials) {
    materials.applyStainlessSteelMaterial(
      this.#parts.getObjectByName(MeshName.cupboardVentCover),
      this.#parts.getObjectByName(MeshName.ovenVentCover),
      this.#parts.getObjectByName(MeshName.classiqueCupboardDoorBar),
      this.#parts.getObjectByName(MeshName.classiqueClunyOvenDoorBar),
      this.#parts.getObjectByName(MeshName.classiqueSullyOvenDoorBar),
      this.#parts.getObjectByName(MeshName.classiqueVolnayOvenDoorBar),
      this.#parts.getObjectByName(MeshName.moderneCupboardDoorBar),
      this.#parts.getObjectByName(MeshName.moderneVolnayCupboardDoorBar),
      this.#parts.getObjectByName(MeshName.moderneClunyOvenDoorBar),
      this.#parts.getObjectByName(MeshName.moderneSullyOvenDoorBar),
      this.#parts.getObjectByName(MeshName.moderneVolnayOvenDoorBar),
      this.#parts.getObjectByName(MeshName.moderneDoorBarSupport)
    );

    materials.applyGalvanizedSteelMaterial(
      this.#parts.getObjectByName(MeshName.moderneClunyDrawerBottomLip),
      this.#parts.getObjectByName(MeshName.moderneSullyDrawerBottomLip),
      this.#parts.getObjectByName(MeshName.moderneVolnayDrawerBottomLip)
    );
  }
}

export { RangeParts };
