import { Group, Object3D } from 'three';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Cluny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
function parts(meshes) {
  // Cupboard Doors
  const leftCupboardDoor = meshes
    .getObjectByName(MeshName.cupboardDoor)
    .clone();
  leftCupboardDoor.name = MeshName.leftCupboardDoor;
  leftCupboardDoor.position.x = -0.702;
  const rightCupboardDoor = leftCupboardDoor.clone();
  rightCupboardDoor.name = MeshName.rightCupboardDoor;
  rightCupboardDoor.position.x = 0.702;

  // Oven Doors
  const rightOvenDoor = meshes.getObjectByName(MeshName.clunyOvenDoor).clone();
  rightOvenDoor.name = MeshName.rightOvenDoor;
  const leftOvenDoor = rightOvenDoor.clone();
  leftOvenDoor.name = MeshName.leftOvenDoor;
  leftOvenDoor.position.x = -0.502;

  // Storage Drawers
  const rightStorageDrawer = meshes
    .getObjectByName(MeshName.clunyStorageDrawer)
    .clone();
  rightStorageDrawer.name = MeshName.rightStorageDrawer;
  const leftStorageDrawer = rightStorageDrawer.clone();
  leftStorageDrawer.name = MeshName.leftStorageDrawer;
  leftStorageDrawer.position.x = -0.502;

  // Cupboard Vent Covers
  const leftCupboardVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  leftCupboardVentCover.position.x = -0.7;
  const rightCupboardVentCover = leftCupboardVentCover.clone();
  rightCupboardVentCover.position.x = 0.7;

  // Oven Vent Covers
  const leftOvenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCover)
    .clone();
  leftOvenVentCover.name = MeshName.leftOvenVentCover;
  leftOvenVentCover.position.x = -0.2445;
  const rightOvenVentCover = leftOvenVentCover.clone();
  rightOvenVentCover.name = MeshName.rightOvenVentCover;
  rightOvenVentCover.position.x = 0.2445;

  return [
    leftCupboardDoor,
    rightCupboardDoor,
    rightOvenDoor,
    leftOvenDoor,
    rightStorageDrawer,
    leftStorageDrawer,
    leftCupboardVentCover,
    rightCupboardVentCover,
    leftOvenVentCover,
    rightOvenVentCover,
  ];
}

/**
 * All the classique trim parts for a Cluny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.864;

  const towelBarSupportMidLeft = meshes
    .getObjectByName(MeshName.towelBarMidLeftSupport)
    .clone();
  towelBarSupportMidLeft.position.x = -0.454;

  const towelBarSupportMidRight = meshes
    .getObjectByName(MeshName.towelBarMidRightSupport)
    .clone();
  towelBarSupportMidRight.position.x = 0.449;

  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.865;

  // Left Oven Door Bar Supports
  const leftOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  leftOvenDoorBarSupportLeft.name = MeshName.leftOvenDoorBarLeftSupport;
  leftOvenDoorBarSupportLeft.position.x = -0.46;
  const leftOvenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  leftOvenDoorBarSupportRight.name = MeshName.leftOvenDoorBarRightSupport;
  leftOvenDoorBarSupportRight.position.x = -0.042;

  // Left Cupboard Door Bar Supports
  const leftCupboardDoorBarSupportLeft = leftOvenDoorBarSupportLeft.clone();
  leftCupboardDoorBarSupportLeft.name = MeshName.leftCupboardBarLeftSupport;
  leftCupboardDoorBarSupportLeft.position.x = -0.814;
  const leftCupboardDoorBarSupportRight = leftOvenDoorBarSupportRight.clone();
  leftCupboardDoorBarSupportRight.name = MeshName.leftCupboardBarRightSupport;
  leftCupboardDoorBarSupportRight.position.x = -0.59;

  // Right Oven Door Bar Supports
  const rightOvenDoorBarSupportLeft = leftOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportLeft.name = MeshName.rightOvenDoorBarLeftSupport;
  rightOvenDoorBarSupportLeft.position.x = 0.042;
  const rightOvenDoorBarSupportRight = leftOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportRight.name = MeshName.rightOvenDoorBarRightSupport;
  rightOvenDoorBarSupportRight.position.x = 0.46;

  // Right Cupboard Bar Supports
  const rightCupboardDoorBarSupportLeft = leftOvenDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportLeft.name = MeshName.rightCupboardBarLeftSupport;
  rightCupboardDoorBarSupportLeft.position.x = 0.59;
  const rightCupboardDoorBarSupportRight = leftOvenDoorBarSupportRight.clone();
  rightCupboardDoorBarSupportRight.name = MeshName.rightCupboardBarRightSupport;
  rightCupboardDoorBarSupportRight.position.x = 0.814;

  // Cupboard Door Bars
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  leftCupboardDoorBar.position.x = -0.701;
  const rightCupboardDoorBar = leftCupboardDoorBar.clone();
  rightCupboardDoorBar.position.x = 0.701;

  // Oven Door Bars
  const rightOvenDoorBar = meshes
    .getObjectByName(MeshName.classiqueClunyOvenDoorBar)
    .clone();
  rightOvenDoorBar.position.x = 0.251;
  const leftOvenDoorBar = rightOvenDoorBar.clone();
  leftOvenDoorBar.position.x = -0.251;

  // Left Drawer Pulls
  const leftDrawerPullRight = meshes
    .getObjectByName(MeshName.drawerPull)
    .clone();
  leftDrawerPullRight.name = MeshName.leftDrawerRightPull;
  leftDrawerPullRight.position.x = -0.082;
  const leftDrawerPullLeft = leftDrawerPullRight.clone();
  leftDrawerPullLeft.name = MeshName.leftDrawerLeftPull;
  leftDrawerPullLeft.position.x = -0.429;

  // Right Drawer Pulls
  const rightDrawerPullLeft = leftDrawerPullRight.clone();
  rightDrawerPullLeft.name = MeshName.rightDrawerLeftPull;
  rightDrawerPullLeft.position.x = 0.078;
  const rightDrawerPullRight = leftDrawerPullRight.clone();
  rightDrawerPullRight.name = MeshName.rightDrawerRightPull;
  rightDrawerPullRight.position.x = 0.42;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportMidLeft,
    towelBarSupportMidRight,
    towelBarSupportRight,
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    leftCupboardDoorBarSupportLeft,
    leftCupboardDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    leftCupboardDoorBar,
    rightCupboardDoorBar,
    leftOvenDoorBar,
    rightOvenDoorBar,
    leftDrawerPullRight,
    leftDrawerPullLeft,
    rightDrawerPullLeft,
    rightDrawerPullRight
  );
}

/**
 * All the moderne trim parts for a Cluny 1800 range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function moderneTrim(meshes) {
  const trim = new Group();

  // Cupboard Door Bars
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  leftCupboardDoorBar.position.x = -0.702;
  const rightCupboardDoorBar = leftCupboardDoorBar.clone();
  rightCupboardDoorBar.position.x = 0.702;

  // Oven Door Bars
  const rightOvenDoorBar = meshes
    .getObjectByName(MeshName.moderneClunyOvenDoorBar)
    .clone();
  rightOvenDoorBar.position.x = 0.251;
  const leftOvenDoorBar = rightOvenDoorBar.clone();
  leftOvenDoorBar.position.x = -0.251;

  // Left Cupboard Bar Supports
  const leftCupboardDoorBarSupportRight = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  leftCupboardDoorBarSupportRight.position.x = -0.58;
  const leftCupboardDoorBarSupportLeft =
    leftCupboardDoorBarSupportRight.clone();
  leftCupboardDoorBarSupportLeft.position.x = -0.82;

  // Left Oven Bar Supports
  const leftOvenDoorBarSupportLeft = leftCupboardDoorBarSupportRight.clone();
  leftOvenDoorBarSupportLeft.position.x = -0.465;
  const leftOvenDoorBarSupportRight = leftOvenDoorBarSupportLeft.clone();
  leftOvenDoorBarSupportRight.position.x = -0.04;

  // Right Oven Bar Supports
  const rightOvenDoorBarSupportLeft = leftOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportLeft.position.x = 0.04;
  const rightOvenDoorBarSupportRight = rightOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportRight.position.x = 0.465;

  // Right Cupboard Bar Supports
  const rightCupboardDoorBarSupportLeft = rightOvenDoorBarSupportRight.clone();
  rightCupboardDoorBarSupportLeft.position.x = 0.58;
  const rightCupboardDoorBarSupportRight =
    rightCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportRight.position.x = 0.82;

  // Storage Drawer Lips
  const rightDrawerLip = meshes
    .getObjectByName(MeshName.moderneClunyDrawerBottomLip)
    .clone();
  rightDrawerLip.position.x = 0.251;
  const leftDrawerLip = rightDrawerLip.clone();
  leftDrawerLip.position.x = -0.251;

  return trim.add(
    leftCupboardDoorBar,
    rightCupboardDoorBar,
    leftOvenDoorBar,
    rightOvenDoorBar,
    leftCupboardDoorBarSupportRight,
    leftCupboardDoorBarSupportLeft,
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    leftDrawerLip,
    rightDrawerLip
  );
}

export { parts, classiqueTrim, moderneTrim };
