import { Group } from 'three';
import { buildBurners } from './burners.js';
import { AssetLoader } from '../../shared/AssetLoader.js';
import { Materials } from '../../shared/Materials.js';

const BACK_BURNER_OFFSET = -0.28;

class RangeTopOptions {
  flameGrill = new Group();
  fourFeuxSpacerGrate;
  inductionRings = new Group();
  multiCooker;
  plancha = new Group();
  tradPlateGroup = new Group();
  optionBases = new Group();

  elevenAnd5KBurners = new Group();
  fiveAnd11KBurners = new Group();
  fifteenAnd5KBurners = new Group();
  fifteenAnd11KBurners = new Group();
  elevenKBurners = new Group();
  fifteenKBurners = new Group();
  eighteenKBurner = new Group();
  leftGrateSpacerTabs = new Group();
  rightGrateSpacerTabs = new Group();
  fourFeuxSpacerTabs = new Group();

  #eighteenKBurnerGrate;
  #twoBurnerGrate = new Group();
  #traditionalPlate;
  #fiveKBurner;
  #elevenKBurner;
  #fifteenKBurner;
  #eighteenKBurner;

  /** @type {AssetLoader} */
  #assets;

  /** @type {Materials} */
  #materials;

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   */
  constructor(assets, materials) {
    this.#assets = assets;
    this.#materials = materials;
    this.#loadModels();
    this.#applyTextures();
    this.#assemble11and5KBurners();
    this.#assemble5and11KBurners();
    this.#assemble15and5KBurners();
    this.#assemble15and11KBurners();
    this.#assemble11KBurners();
    this.#assemble15KBurners();
    this.#assemble18KBurner();
    this.#assembleTradPlate();
    this.#assembleGrateSpacerTabs();
  }

  #loadModels() {
    // Flame Grill
    while (this.#assets.flameGrillData.scene.children.length) {
      this.flameGrill.add(this.#assets.flameGrillData.scene.children[0]);
    }

    // Induction Rings
    while (this.#assets.inductionRingsData.scene.children.length) {
      this.inductionRings.add(
        this.#assets.inductionRingsData.scene.children[0]
      );
    }

    // Multi-Cooker
    this.multiCooker = this.#assets.multiCookerData.scene.children[0];

    // Plancha
    while (this.#assets.planchaData.scene.children.length) {
      this.plancha.add(this.#assets.planchaData.scene.children[0]);
    }

    // 18k Burner Grate
    this.#eighteenKBurnerGrate =
      this.#assets.eighteenKBurnerGrateData.scene.children[0];

    // 2 Burner Grate
    while (this.#assets.twoBurnerGrateData.scene.children.length) {
      this.#twoBurnerGrate.add(
        this.#assets.twoBurnerGrateData.scene.children[0]
      );
    }

    // 4-Feux Spacer Grate
    this.fourFeuxSpacerGrate =
      this.#assets.fourFeuxSpacerData.scene.children[0];

    // Traditional (Simmer) Plate
    this.#traditionalPlate =
      this.#assets.traditionalPlateData.scene.children[0];

    // 5k, 11k, 15k, 18k Burners
    [
      this.#fiveKBurner,
      this.#elevenKBurner,
      this.#fifteenKBurner,
      this.#eighteenKBurner,
    ] = buildBurners(this.#assets.allBurnersData.scene, this.#materials);

    // Range Top Option Bases
    while (this.#assets.rangetopPiecesData.scene.children.length) {
      this.optionBases.add(this.#assets.rangetopPiecesData.scene.children[0]);
    }
  }

  #applyTextures() {
    this.#applyFlameGrillTextures(this.flameGrill);
    this.#applyInductionRingsTexture(this.inductionRings);
    this.#applyPlanchaTextures(this.plancha);

    this.#materials.applyStainlessSteelMaterial(this.multiCooker);

    this.#materials.applyEnamelCastIronMaterial(
      this.#eighteenKBurnerGrate,
      ...this.#twoBurnerGrate.children,
      this.fourFeuxSpacerGrate,
      this.#traditionalPlate
    );
  }

  #applyFlameGrillTextures(flameGrill) {
    this.#materials.applyStainlessSteelMaterial(
      flameGrill.getObjectByName('RO2_GG'), // flame grill cooking surface
      flameGrill.getObjectByName('RO2_GG001') // flame grill briquet holder
    );

    this.#materials.applyBrassMaterial(
      flameGrill.getObjectByName('RO2_Handel_GG'), // flame grill rear posts
      flameGrill.getObjectByName('RO2_Handel_GG001') // flame grill handles
    );

    // TODO: Find a better material for this
    this.#materials.applyEnamelCastIronMaterial(
      flameGrill.getObjectByName('RO2_GG002') // flame grill briquets
    );
  }

  #applyInductionRingsTexture(inductionRings) {
    this.#materials.applyMatteBlackMaterial(
      inductionRings.getObjectByName('RO2_2IR_P') // induction burner surface
    );
  }

  #applyPlanchaTextures(plancha) {
    this.#materials.applyStainlessSteelMaterial(
      plancha.getObjectByName('RO2_EP_T'), // plancha base
      plancha.getObjectByName('RO2_EP_O_T') // plancha cover
    );

    this.#materials.applyBrushedSSMaterial(
      plancha.getObjectByName('RO2_EP_O_Ring_T') // plancha handle bezel
    );
  }

  #assemble11and5KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front11KBurner = this.#elevenKBurner.clone();
    const back5KBurner = this.#fiveKBurner.clone();
    back5KBurner.translateZ(BACK_BURNER_OFFSET);

    this.elevenAnd5KBurners.add(twoBurnerGrate, front11KBurner, back5KBurner);
  }

  #assemble5and11KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front5KBurner = this.#fiveKBurner.clone();
    const back11KBurner = this.#elevenKBurner.clone();
    back11KBurner.translateZ(BACK_BURNER_OFFSET);

    this.fiveAnd11KBurners.add(twoBurnerGrate, front5KBurner, back11KBurner);
  }

  #assemble15and5KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front15KBurner = this.#fifteenKBurner.clone();
    const back5KBurner = this.#fiveKBurner.clone();
    back5KBurner.translateZ(BACK_BURNER_OFFSET);

    this.fifteenAnd5KBurners.add(twoBurnerGrate, front15KBurner, back5KBurner);
  }

  #assemble15and11KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front15KBurner = this.#fifteenKBurner.clone();
    const back11KBurner = this.#elevenKBurner.clone();
    back11KBurner.translateZ(BACK_BURNER_OFFSET);

    this.fifteenAnd11KBurners.add(
      twoBurnerGrate,
      front15KBurner,
      back11KBurner
    );
  }

  #assemble11KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front11KBurner = this.#elevenKBurner.clone();
    const back11KBurner = front11KBurner.clone();
    back11KBurner.translateZ(BACK_BURNER_OFFSET);

    this.elevenKBurners.add(twoBurnerGrate, front11KBurner, back11KBurner);
  }

  #assemble15KBurners() {
    const twoBurnerGrate = this.#twoBurnerGrate
      .getObjectByName('2_Burner_Grate')
      .clone();
    const front15KBurner = this.#fifteenKBurner.clone();
    const back15KBurner = front15KBurner.clone();
    back15KBurner.translateZ(BACK_BURNER_OFFSET);

    this.fifteenKBurners.add(twoBurnerGrate, front15KBurner, back15KBurner);
  }

  #assemble18KBurner() {
    const left18kBurnerGrate = this.#eighteenKBurnerGrate;
    const right18kBurnerGrate = left18kBurnerGrate.clone();
    right18kBurnerGrate.rotateZ(Math.PI);
    const center18kBurner = this.#eighteenKBurner.clone();

    this.eighteenKBurner.add(
      left18kBurnerGrate,
      right18kBurnerGrate,
      center18kBurner
    );
  }

  #assembleTradPlate() {
    const traditionalPlate = this.#traditionalPlate.clone();
    const center18KBurner = this.#eighteenKBurner.clone();

    this.tradPlateGroup.add(traditionalPlate, center18KBurner);
  }

  #assembleGrateSpacerTabs() {
    const leftSpacer = this.#twoBurnerGrate.getObjectByName('Spacer').clone();
    const rightSpacer = leftSpacer.clone();
    rightSpacer.rotateZ(Math.PI);
    rightSpacer.position.x += -0.0275;

    this.fourFeuxSpacerTabs.add(leftSpacer.clone(), rightSpacer.clone());

    this.leftGrateSpacerTabs.add(leftSpacer);

    rightSpacer.position.x += 0.312;
    this.rightGrateSpacerTabs.add(rightSpacer);
  }
}

export { RangeTopOptions };
