import { Group, Mesh, Object3D } from 'three';
import { MeshName } from '../../shared/Enums.js';

/**
 * Various external (common) parts of the Fontenay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
function parts(meshes) {
  // Cupboard Doors
  const leftCupboardDoor = meshes
    .getObjectByName(MeshName.cupboardDoor)
    .clone();
  leftCupboardDoor.name = MeshName.leftCupboardDoor;
  leftCupboardDoor.position.x = -0.552;
  const rightCupboardDoor = leftCupboardDoor.clone();
  rightCupboardDoor.name = MeshName.rightCupboardDoor;
  rightCupboardDoor.position.x = 0.552;

  // Oven Door
  const ovenDoor = meshes.getObjectByName(MeshName.sullyOvenDoor).clone();
  ovenDoor.name = MeshName.ovenDoor;
  ovenDoor.position.x = 0;

  // Storage Drawer
  const storageDrawer = meshes
    .getObjectByName(MeshName.sullyStorageDrawer)
    .clone();
  storageDrawer.name = MeshName.storageDrawer;
  storageDrawer.position.x = 0;

  // Cupboard Vent Covers
  const leftCupboardVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  leftCupboardVentCover.position.x = -0.547;
  const rightCupboardVentCover = leftCupboardVentCover.clone();
  rightCupboardVentCover.position.x = 0.547;

  // Oven Vent Cover
  const ovenVentCover = meshes.getObjectByName(MeshName.ovenVentCover).clone();
  ovenVentCover.position.x = 0;

  return [
    leftCupboardDoor,
    rightCupboardDoor,
    ovenDoor,
    storageDrawer,
    leftCupboardVentCover,
    rightCupboardVentCover,
    ovenVentCover,
  ];
}

/**
 * All the classique trim parts for a Fontenay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName(MeshName.towelBarleftSupport)
    .clone();
  towelBarSupportLeft.position.x = -0.715;
  const towelBarSupportMidLeft = meshes
    .getObjectByName(MeshName.towelBarMidLeftSupport)
    .clone();
  towelBarSupportMidLeft.position.x = -0.36;
  const towelBarSupportMidRight = meshes
    .getObjectByName(MeshName.towelBarMidRightSupport)
    .clone();
  towelBarSupportMidRight.position.x = 0.345;
  const towelBarSupportRight = meshes
    .getObjectByName(MeshName.towelBarRightSupport)
    .clone();
  towelBarSupportRight.position.x = 0.715;

  // Left Cupboard Door Bar Supports
  const leftCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  leftCupboardDoorBarSupportLeft.name = MeshName.leftCupboardBarLeftSupport;
  leftCupboardDoorBarSupportLeft.position.x = -0.685;
  const leftCupboardDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  leftCupboardDoorBarSupportRight.name = MeshName.leftCupboardBarRightSupport;
  leftCupboardDoorBarSupportRight.position.x = -0.42;

  // Right Cupboard Door Bar Supports
  const rightCupboardDoorBarSupportLeft =
    leftCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportLeft.name = MeshName.rightCupboardBarLeftSupport;
  rightCupboardDoorBarSupportLeft.position.x = 0.42;
  const rightCupboardDoorBarSupportRight =
    leftCupboardDoorBarSupportRight.clone();
  rightCupboardDoorBarSupportRight.name = MeshName.rightCupboardBarRightSupport;
  rightCupboardDoorBarSupportRight.position.x = 0.685;

  // Cupboard Door Bars
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.classiqueCupboardDoorBar)
    .clone();
  leftCupboardDoorBar.position.x = -0.55;
  const rightCupboardDoorBar = leftCupboardDoorBar.clone();
  rightCupboardDoorBar.position.x = 0.55;

  // Oven Door Bar Supports
  const ovenDoorBarSupportLeft = rightCupboardDoorBarSupportLeft.clone();
  ovenDoorBarSupportLeft.name = MeshName.ovenDoorBarLeftSupport;
  ovenDoorBarSupportLeft.position.x = -0.3;
  const ovenDoorBarSupportRight = rightCupboardDoorBarSupportRight.clone();
  ovenDoorBarSupportRight.name = MeshName.ovenDoorBarRightSupport;
  ovenDoorBarSupportRight.position.x = 0.3;

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.classiqueSullyOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = 0;

  // Drawer Pulls
  const drawerPullLeft = meshes.getObjectByName(MeshName.drawerPull).clone();
  drawerPullLeft.name = MeshName.drawerLeftPull;
  drawerPullLeft.position.x = -0.2;
  const drawerPullRight = drawerPullLeft.clone();
  drawerPullRight.name = MeshName.drawerRightPull;
  drawerPullRight.position.x = 0.2;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportMidLeft,
    towelBarSupportMidRight,
    towelBarSupportRight,
    leftCupboardDoorBarSupportLeft,
    leftCupboardDoorBarSupportRight,
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    leftCupboardDoorBar,
    rightCupboardDoorBar,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    ovenDoorBar,
    drawerPullLeft,
    drawerPullRight
  );
}

/**
 * All the moderne trim parts for a Fontenay range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function moderneTrim(meshes) {
  const trim = new Group();

  // Left Cupboard Bar Supports
  const leftCupboardDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  leftCupboardDoorBarSupportLeft.position.x = -0.67;
  const leftCupboardDoorBarSupportRight =
    leftCupboardDoorBarSupportLeft.clone();
  leftCupboardDoorBarSupportRight.position.x = -0.43;

  // Right Cupboard Bar Supports
  const rightCupboardDoorBarSupportLeft =
    leftCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportLeft.position.x = 0.43;
  const rightCupboardDoorBarSupportRight =
    rightCupboardDoorBarSupportLeft.clone();
  rightCupboardDoorBarSupportRight.position.x = 0.67;

  // Cupboard Door Bars
  const leftCupboardDoorBar = meshes
    .getObjectByName(MeshName.moderneCupboardDoorBar)
    .clone();
  leftCupboardDoorBar.position.x = -0.55;
  const rightCupboardDoorBar = leftCupboardDoorBar.clone();
  rightCupboardDoorBar.position.x = 0.55;

  // Oven Bar Supports
  const ovenDoorBarSupportLeft = rightCupboardDoorBarSupportRight.clone();
  ovenDoorBarSupportLeft.position.x = -0.3;
  const ovenDoorBarSupportRight = ovenDoorBarSupportLeft.clone();
  ovenDoorBarSupportRight.position.x = 0.3;

  // Oven Door Bar
  const ovenDoorBar = meshes
    .getObjectByName(MeshName.moderneSullyOvenDoorBar)
    .clone();
  ovenDoorBar.position.x = 0;

  // Storage Drawer Lip
  const leftDrawerLip = meshes
    .getObjectByName(MeshName.moderneSullyDrawerBottomLip)
    .clone();
  leftDrawerLip.position.x = 0;

  return trim.add(
    leftCupboardDoorBarSupportLeft,
    leftCupboardDoorBarSupportRight,
    rightCupboardDoorBarSupportLeft,
    rightCupboardDoorBarSupportRight,
    leftCupboardDoorBar,
    rightCupboardDoorBar,
    ovenDoorBarSupportLeft,
    ovenDoorBarSupportRight,
    ovenDoorBar,
    leftDrawerLip
  );
}

export { classiqueTrim, moderneTrim, parts };
