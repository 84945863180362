import { Group, Mesh, Object3D } from 'three';
import { MeshName } from '../../shared/Enums';

/**
 * Oven doors and storage drawers for a Sully, Sully 1800, and Sully 2200 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
function sullyOvenDoorsAndDrawers(meshes, offset = 0) {
  // Oven Doors
  const leftOvenDoor = meshes.getObjectByName(MeshName.sullyOvenDoor).clone();
  leftOvenDoor.name = MeshName.leftOvenDoor;
  leftOvenDoor.translateX(offset);
  const rightOvenDoor = leftOvenDoor.clone();
  rightOvenDoor.name = MeshName.rightOvenDoor;
  rightOvenDoor.position.x = offset + 0.351;

  // Storage Drawers
  const leftStorageDrawer = meshes
    .getObjectByName(MeshName.sullyStorageDrawer)
    .clone();
  leftStorageDrawer.name = MeshName.leftStorageDrawer;
  leftStorageDrawer.translateX(offset);
  const rightStorageDrawer = leftStorageDrawer.clone();
  rightStorageDrawer.name = MeshName.rightStorageDrawer;
  rightStorageDrawer.position.x = offset + 0.351;

  // Standard Oven Vent Covers
  const leftOvenVentCover = meshes
    .getObjectByName(MeshName.ovenVentCover)
    .clone();
  leftOvenVentCover.name = MeshName.leftOvenVentCover;
  leftOvenVentCover.translateX(offset);
  const rightOvenVentCover = leftOvenVentCover.clone();
  rightOvenVentCover.name = MeshName.rightOvenVentCover;
  rightOvenVentCover.position.x = offset + 0.3445;

  return [
    leftOvenDoor,
    rightOvenDoor,
    leftStorageDrawer,
    rightStorageDrawer,
    leftOvenDoor,
    leftOvenVentCover,
    rightOvenVentCover,
  ];
}

/**
 * All the oven and storage drawer classique trim parts for a Sully, Sully 1800,
 * and Sully 2200 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
function sullyOvenClassiqueTrim(meshes, offset = 0) {
  // Left Oven Door Bar Supports
  const leftOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.doorBarLeftSupport)
    .clone();
  leftOvenDoorBarSupportLeft.name = MeshName.leftOvenDoorBarLeftSupport;
  leftOvenDoorBarSupportLeft.position.x += offset;
  const leftOvenDoorBarSupportRight = meshes
    .getObjectByName(MeshName.doorBarRightSupport)
    .clone();
  leftOvenDoorBarSupportRight.name = MeshName.leftOvenDoorBarRightSupport;
  leftOvenDoorBarSupportRight.position.x += offset;

  // Right Oven Door Bar Supports
  const rightOvenDoorBarSupportLeft = leftOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportLeft.name = MeshName.rightOvenDoorBarLeftSupport;
  rightOvenDoorBarSupportLeft.position.x = offset + 0.042;
  const rightOvenDoorBarSupportRight = leftOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportRight.name = MeshName.rightOvenDoorBarRightSupport;
  rightOvenDoorBarSupportRight.position.x = offset + 0.66;

  // Oven Door Bars
  const leftOvenDoorBar = meshes
    .getObjectByName(MeshName.classiqueSullyOvenDoorBar)
    .clone();
  leftOvenDoorBar.position.x += offset;
  const rightOvenDoorBar = leftOvenDoorBar.clone();
  rightOvenDoorBar.position.x = offset + 0.351;

  // Left Drawer Pulls
  const leftDrawerPullRight = meshes
    .getObjectByName(MeshName.drawerPull)
    .clone();
  leftDrawerPullRight.name = MeshName.leftDrawerRightPull;
  leftDrawerPullRight.position.x += offset;
  const leftDrawerPullLeft = leftDrawerPullRight.clone();
  leftDrawerPullLeft.name = MeshName.leftDrawerLeftPull;
  leftDrawerPullLeft.position.x = offset - 0.6;

  // Right Drawer Pulls
  const rightDrawerPullLeft = leftDrawerPullRight.clone();
  rightDrawerPullLeft.name = MeshName.rightDrawerLeftPull;
  rightDrawerPullLeft.position.x = offset + 0.1025;
  const rightDrawerPullRight = leftDrawerPullRight.clone();
  rightDrawerPullRight.name = MeshName.rightDrawerRightPull;
  rightDrawerPullRight.position.x = offset + 0.6;

  return [
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    leftOvenDoorBar,
    rightOvenDoorBar,
    leftDrawerPullLeft,
    leftDrawerPullRight,
    rightDrawerPullLeft,
    rightDrawerPullRight,
  ];
}

/**
 * All the oven and storage drawer moderne trim parts for a Sully, Sully 1800,
 * and Sully 2200 range
 * @param {Group} meshes - Common parts of most ranges
 * @param {number} [offset] - Adjust the trim to the left or right (in meters)
 * @returns {Object3D[]}
 */
function sullyOvenModerneTrim(meshes, offset = 0) {
  // Left Oven Bar Supports
  const leftOvenDoorBarSupportLeft = meshes
    .getObjectByName(MeshName.moderneDoorBarSupport)
    .clone();
  leftOvenDoorBarSupportLeft.position.x = offset - 0.66;
  const leftOvenDoorBarSupportRight = leftOvenDoorBarSupportLeft.clone();
  leftOvenDoorBarSupportRight.position.x = offset - 0.04;

  // Right Oven Bar Supports
  const rightOvenDoorBarSupportLeft = leftOvenDoorBarSupportRight.clone();
  rightOvenDoorBarSupportLeft.position.x = offset + 0.04;
  const rightOvenDoorBarSupportRight = rightOvenDoorBarSupportLeft.clone();
  rightOvenDoorBarSupportRight.position.x = offset + 0.66;

  // Oven Door Bars
  const leftOvenDoorBar = meshes
    .getObjectByName(MeshName.moderneSullyOvenDoorBar)
    .clone();
  leftOvenDoorBar.translateX(offset);
  const rightOvenDoorBar = leftOvenDoorBar.clone();
  rightOvenDoorBar.position.x = offset + 0.351;

  // Storage Drawer Lips
  const leftDrawerLip = meshes
    .getObjectByName(MeshName.moderneSullyDrawerBottomLip)
    .clone();
  leftDrawerLip.translateX(offset);
  const rightDrawerLip = leftDrawerLip.clone();
  rightDrawerLip.position.x = offset + 0.351;

  return [
    leftOvenDoorBarSupportLeft,
    leftOvenDoorBarSupportRight,
    rightOvenDoorBarSupportLeft,
    rightOvenDoorBarSupportRight,
    leftOvenDoorBar,
    rightOvenDoorBar,
    leftDrawerLip,
    rightDrawerLip,
  ];
}

export {
  sullyOvenClassiqueTrim,
  sullyOvenDoorsAndDrawers,
  sullyOvenModerneTrim,
};
