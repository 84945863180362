import { Group } from 'three';

import { BaseKnobs } from '../shared/BaseKnobs';
import { KnobModels } from '../shared/KnobModels';

import { Burner, Cupboard, Oven, Region } from '../../shared/Enums';
import { Materials } from '../../shared/Materials';
import { State } from '../../shared/State';

class Knobs extends BaseKnobs {
  /**
   * The knobs that selected but not necessarily displayed (yet) on the range
   * @type {Object.<string, Object.<string, ?string>>}
   */
  selectedKnobs = {
    left: {
      baseLeftBurnerFront: Burner.gasBurner,
      baseLeftBurnerBack: Burner.gasBurner,
      baseLeftBurnerCenter: Burner.gasBurner,
      baseRightBurnerBack: null,
      baseRightBurnerFront: null,
      oven: Oven.convection,
    },
    farRight: {
      optionBack: null,
      optionFront: null,
      cupboard: Cupboard.warming,
    },
  };

  /**
   * @param {KnobModels} knobModels
   * @param {Group} range
   * @param {State} state
   * @param {Materials} materials
   */
  constructor(knobModels, range, state, materials) {
    super(knobModels, range, state, materials);
  }

  /**
   * Change the cupboard to have a knob for a warming cupboard
   * @param {string} region
   */
  selectWarmingCupboard(region) {
    switch (region) {
      case Region.farRight:
        this.selectedKnobs.farRight.cupboard = Cupboard.warming;
        this.updateRightKnobs();
        break;
    }
  }

  /**
   * Remove the warming cupboard knob
   * @param {string} region
   */
  selectStorageCupboard(region) {
    switch (region) {
      case Region.right:
        this.selectedKnobs.right.cupboard = null;
        this.updateRightKnobs();
        break;
    }
  }

  /** Update the knobs on the range's left */
  updateLeftKnobs() {
    this.updateKnobRegion(Region.left, {
      maxKnobs: 5,
      offset: 0.54,
      gap: 0.11,
    });
  }

  /** Update the knobs on the range's right */
  updateFarRightKnobs() {
    this.updateKnobRegion(Region.farRight, {
      maxKnobs: 3,
      offset: 1.2,
      gap: 0.1,
    });
  }
}

export { Knobs };
