import { BufferGeometry, Group, Mesh, Object3D } from 'three';
import { RangeTopOptions } from './RangeTopOptions';

class BaseSSRangeTop {
  /**
   * A range top geometry for a flame grill
   * @type {BufferGeometry}
   */
  #flameGrillPondGeo;

  /**
   * A range top pond geometry for a Fontenay 4-feux burner assembly
   * @type {BufferGeometry}
   */
  #fontenay4BurnerPondGeo;

  /**
   * A range top geometry for a single 18k burner or multi-cooker
   * @type {BufferGeometry}
   */
  #oneBurnerPondGeo;

  /**
   * A 13mm wide spacer geometry
   * @type {BufferGeometry}
   */
  #spacer13mmGeo;

  /**
   * A 14mm wide spacer geometry
   * @type {BufferGeometry}
   */
  #spacer14mmGeo;

  /**
   * A 16mm wide spacer geometry
   * @type {BufferGeometry}
   */
  #spacer16mmGeo;

  /**
   * A 18mm wide spacer geometry
   * @type {BufferGeometry}
   */
  #spacer18mmGeo;

  /**
   * A 22mm wide spacer geometry
   * @type {BufferGeometry}
   */
  #spacer22mmGeo;

  /**
   * A 23.5mm wide spacer geometry
   * @type {BufferGeometry}
   */
  #spacer23_5mmGeo;

  /**
   * A 26mm wide spacer geometry
   * @type {BufferGeometry}
   */
  #spacer26mmGeo;

  /**
   * A 28mm wide spacer geometry
   * @type {BufferGeometry}
   */
  #spacer28mmGeo;

  /**
   * A range top pond geometry for a Sully 4-feux burner assembly
   * @type {BufferGeometry}
   */
  #sully4BurnerPondGeo;

  /**
   * A range top narrow pond geometry for two burners
   * @type {BufferGeometry}
   */
  #twoBurnerPondNarrowGeo;

  /**
   * A range top wide pond geometry for two burners
   * @type {BufferGeometry}
   */
  #twoBurnerPondWideGeo;

  /**
   * A narrow stainless steel workstation geometry
   * @type {BufferGeometry}
   */
  #workstationNarrowGeo;

  /**
   * A wide (standard) stainless steel workstation geometry
   * @type {BufferGeometry}
   */
  #workstationWideGeo;

  /**
   * @param {RangeTopOptions} rangeTopOptions
   * @param {number} offset - x-axis offset relative to a Sully 2200 range top
   */
  constructor(rangeTopOptions, offset) {
    const optionBases = rangeTopOptions.optionBases;

    this.#make1BurnerPondGeometry(optionBases, offset);
    this.#makeWide2BurnerPondGeometry(optionBases, offset);
    this.#makeNarrow2BurnerPondGeometry(optionBases, offset);
    this.#makeWideWorkstationGeometry(optionBases, offset);
    this.#makeNarrowWorkstationGeometry(optionBases, offset);
    this.#makeFlameGrillPondGeometry(optionBases, offset);
    this.#makeSully4BurnerPondGeometry(optionBases, offset);
    this.#makeFontenay4BurnerPondGeometry(optionBases, offset);
    this.#make13mmSpacerGeometry(optionBases, offset);
    this.#make14mmSpacerGeometry(optionBases, offset);
    this.#make16mmSpacerGeometry(optionBases, offset);
    this.#make18mmSpacerGeometry(optionBases, offset);
    this.#make22mmSpacerGeometry(optionBases, offset);
    this.#make23_5mmSpacerGeometry(optionBases, offset);
    this.#make26mmSpacerGeometry(optionBases, offset);
    this.#make28mmSpacerGeometry(optionBases, offset);
  }

  /**
   * A 1 burner pond geometry for an 18k burner, traditional simmer plate, or multi-cooker
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  oneBurnerPond(offset) {
    const pond = this.#oneBurnerPondGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  /**
   * A wide pond geometry for a 2 burner option
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  wide2BurnerPond(offset) {
    const pond = this.#twoBurnerPondWideGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  /**
   * A narrow pond geometry for a 2 burner option
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  narrow2BurnerPond(offset) {
    const pond = this.#twoBurnerPondNarrowGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  /**
   * A wide (standard) stainless steel workstation geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  wideWorkstation(offset) {
    const workstation = this.#workstationWideGeo.clone();
    workstation.translate(offset, 0, 0);

    return workstation;
  }

  /**
   * A narrow stainless steel workstation geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  narrowWorkstation(offset) {
    const workstation = this.#workstationNarrowGeo.clone();
    workstation.translate(offset, 0, 0);

    return workstation;
  }

  /**
   * A range top geometry below a flame grill
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  flameGrillPond(offset) {
    const pond = this.#flameGrillPondGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  /**
   * A pond geometry for a Sully 4-feux burner assembly
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  sully4BurnerPond(offset) {
    const pond = this.#sully4BurnerPondGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  /**
   * A pond geometry for a Fontenay 4-feux burner assembly
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  fontenay4BurnerPond(offset) {
    const pond = this.#fontenay4BurnerPondGeo.clone();
    pond.translate(offset, 0, 0);

    return pond;
  }

  /**
   * A 13mm wide spacer geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  spacer13mm(offset) {
    const spacer = this.#spacer13mmGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  /**
   * A 14mm wide spacer geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  spacer14mm(offset) {
    const spacer = this.#spacer14mmGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  /**
   * A 16mm wide spacer geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  spacer16mm(offset) {
    const spacer = this.#spacer16mmGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  /**
   * A 18mm wide spacer geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  spacer18mm(offset) {
    const spacer = this.#spacer18mmGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  /**
   * A 22mm wide spacer geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  spacer22mm(offset) {
    const spacer = this.#spacer22mmGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  /**
   * A 23.5mm wide spacer geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  spacer23_5mm(offset) {
    const spacer = this.#spacer23_5mmGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  /**
   * A 26mm wide spacer geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  spacer26mm(offset) {
    const spacer = this.#spacer26mmGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  /**
   * A 28mm wide spacer geometry
   * @param {number} offset
   * @returns {BufferGeometry}
   */
  spacer28mm(offset) {
    const spacer = this.#spacer28mmGeo.clone();
    spacer.translate(offset, 0, 0);

    return spacer;
  }

  /**
   * Create a geometry for a wide (standard) flat workstation
   * @param {Group} optionBases
   * @param {number} offset
   */
  #makeWideWorkstationGeometry(optionBases, offset) {
    const wideStation = optionBases.getObjectByName('Workstation_Wide');

    // @ts-ignore
    this.#workstationWideGeo = wideStation.geometry.clone();
    this.#workstationWideGeo.applyQuaternion(wideStation.quaternion);
    this.#workstationWideGeo.translate(
      // Align to inside left of range top rim
      wideStation.position.x + offset,
      wideStation.position.y,
      wideStation.position.z
    );
  }

  /**
   * Create a geometry for a narrow (Sully only) flat workstation
   * @param {Group} optionBases
   * @param {number} offset
   */
  #makeNarrowWorkstationGeometry(optionBases, offset) {
    const narrowStation = optionBases.getObjectByName('Workstation_Narrow');

    // @ts-ignore
    this.#workstationNarrowGeo = narrowStation.geometry.clone();
    this.#workstationNarrowGeo.applyQuaternion(narrowStation.quaternion);
    this.#workstationNarrowGeo.translate(
      // Align to inside left of range top rim
      narrowStation.position.x + offset - 0.416,
      narrowStation.position.y,
      narrowStation.position.z
    );
  }

  /**
   * Create a geometry for the 1 burner pond
   * @param {Group} optionBases
   * @param {number} offset
   */
  #make1BurnerPondGeometry(optionBases, offset) {
    const oneBurnerPond = optionBases.getObjectByName('1_Burner_Pond');

    // @ts-ignore
    this.#oneBurnerPondGeo = oneBurnerPond.geometry.clone();
    this.#oneBurnerPondGeo.applyQuaternion(oneBurnerPond.quaternion);
    this.#oneBurnerPondGeo.translate(
      // Align to inside left of range top rim
      oneBurnerPond.position.x + offset - 0.702,
      oneBurnerPond.position.y,
      oneBurnerPond.position.z
    );
  }

  /**
   * Create a geometry for a narrow 2 burner pond (Sully only)
   * @param {Group} optionBases
   * @param {number} offset
   */
  #makeNarrow2BurnerPondGeometry(optionBases, offset) {
    const narrowPond = optionBases.getObjectByName('2_Burner_Pond_Narrow');

    // @ts-ignore
    this.#twoBurnerPondNarrowGeo = narrowPond.geometry.clone();
    this.#twoBurnerPondNarrowGeo.applyQuaternion(narrowPond.quaternion);
    this.#twoBurnerPondNarrowGeo.translate(
      // Align to inside left of range top rim
      narrowPond.position.x + offset - 1.118,
      narrowPond.position.y,
      narrowPond.position.z
    );
  }

  /**
   * Create a geometry for a wide 2 burner pond
   * @param {Group} optionBases
   * @param {number} offset
   */
  #makeWide2BurnerPondGeometry(optionBases, offset) {
    const widePond = optionBases.getObjectByName('2_Burner_Pond_Wide');

    // @ts-ignore
    this.#twoBurnerPondWideGeo = widePond.geometry.clone();
    this.#twoBurnerPondWideGeo.applyQuaternion(widePond.quaternion);
    this.#twoBurnerPondWideGeo.translate(
      // Align to inside left of range top rim
      widePond.position.x + offset - 1.391,
      widePond.position.y,
      widePond.position.z
    );
  }

  /**
   * Create a geometry for a Sully 4-feux burner pond
   * @param {Group} optionBases
   * @param {number} offset
   */
  #makeSully4BurnerPondGeometry(optionBases, offset) {
    const fourFeuxPond = optionBases.getObjectByName('Sully_4_Burner_Pond');

    // @ts-ignore
    this.#sully4BurnerPondGeo = fourFeuxPond.geometry.clone();
    this.#sully4BurnerPondGeo.applyQuaternion(fourFeuxPond.quaternion);
    this.#sully4BurnerPondGeo.translate(
      // Align to inside left of range top rim
      fourFeuxPond.position.x + offset - 1.781,
      fourFeuxPond.position.y,
      fourFeuxPond.position.z
    );
  }

  /**
   * Create a geometry for a Fontenay 4-feux burner pond
   * @param {Group} optionBases
   * @param {number} offset
   */
  #makeFontenay4BurnerPondGeometry(optionBases, offset) {
    const fontenayPond = optionBases.getObjectByName('Fontenay_4_Burner_Pond');

    // @ts-ignore
    this.#fontenay4BurnerPondGeo = fontenayPond.geometry.clone();
    this.#fontenay4BurnerPondGeo.applyQuaternion(fontenayPond.quaternion);
    this.#fontenay4BurnerPondGeo.translate(
      // Align to inside left of range top rim
      fontenayPond.position.x + offset - 2.8105,
      fontenayPond.position.y,
      fontenayPond.position.z
    );
  }

  /**
   * Create a geometry to hold a flame grill
   * @param {Group} optionBases
   * @param {number} offset
   */
  #makeFlameGrillPondGeometry(optionBases, offset) {
    const flameGrillPond = optionBases.getObjectByName('Flame_Grill_Pond');

    // @ts-ignore
    this.#flameGrillPondGeo = flameGrillPond.geometry.clone();
    this.#flameGrillPondGeo.applyQuaternion(flameGrillPond.quaternion);
    this.#flameGrillPondGeo.translate(
      // Align to inside left of range top rim
      flameGrillPond.position.x + offset - 2.353,
      flameGrillPond.position.y,
      flameGrillPond.position.z
    );
  }

  /**
   * Create a geometry for a 13mm wide spacer
   * @param {Group} optionBases
   * @param {number} offset
   */
  #make13mmSpacerGeometry(optionBases, offset) {
    const spacer13mm = optionBases.getObjectByName('13mm_Spacer');

    // @ts-ignore
    this.#spacer13mmGeo = spacer13mm.geometry.clone();
    this.#spacer13mmGeo.applyQuaternion(spacer13mm.quaternion);
    this.#spacer13mmGeo.translate(
      // Align to inside left of range top rim
      spacer13mm.position.x + offset - 1.378,
      spacer13mm.position.y,
      spacer13mm.position.z
    );
  }

  /**
   * Create a geometry for a 13mm wide spacer
   * @param {Group} optionBases
   * @param {number} offset
   */
  #make14mmSpacerGeometry(optionBases, offset) {
    const spacer14mm = optionBases.getObjectByName('14mm_Spacer');

    // @ts-ignore
    this.#spacer14mmGeo = spacer14mm.geometry.clone();
    this.#spacer14mmGeo.applyQuaternion(spacer14mm.quaternion);
    this.#spacer14mmGeo.translate(
      // Align to inside left of range top rim
      spacer14mm.position.x + offset - 3.5284,
      spacer14mm.position.y,
      spacer14mm.position.z
    );
  }

  /**
   * Create a geometry for a 16mm wide spacer
   * @param {Group} optionBases
   * @param {number} offset
   */
  #make16mmSpacerGeometry(optionBases, offset) {
    const spacer16mm = optionBases.getObjectByName('16mm_Spacer');

    // @ts-ignore
    this.#spacer16mmGeo = spacer16mm.geometry.clone();
    this.#spacer16mmGeo.applyQuaternion(spacer16mm.quaternion);
    this.#spacer16mmGeo.translate(
      // Align to inside left of range top rim
      spacer16mm.position.x + offset - 2.7945,
      spacer16mm.position.y,
      spacer16mm.position.z
    );
  }

  /**
   * Create a geometry for a 18mm wide spacer
   * @param {Group} optionBases
   * @param {number} offset
   */
  #make18mmSpacerGeometry(optionBases, offset) {
    const spacer18mm = optionBases.getObjectByName('18mm_Spacer');

    // @ts-ignore
    this.#spacer18mmGeo = spacer18mm.geometry.clone();
    this.#spacer18mmGeo.applyQuaternion(spacer18mm.quaternion);
    this.#spacer18mmGeo.translate(
      // Align to inside left of range top rim
      spacer18mm.position.x + offset - 3.4885,
      spacer18mm.position.y,
      spacer18mm.position.z
    );
  }

  /**
   * Create a geometry for a 22mm wide spacer
   * @param {Group} optionBases
   * @param {number} offset
   */
  #make22mmSpacerGeometry(optionBases, offset) {
    const spacer22mm = optionBases.getObjectByName('22mm_Spacer');

    // @ts-ignore
    this.#spacer22mmGeo = spacer22mm.geometry.clone();
    this.#spacer22mmGeo.applyQuaternion(spacer22mm.quaternion);
    this.#spacer22mmGeo.translate(
      // Align to inside left of range top rim
      spacer22mm.position.x + offset - 3.5065,
      spacer22mm.position.y,
      spacer22mm.position.z
    );
  }

  /**
   * Create a geometry for a 23.5mm wide spacer
   * @param {Group} optionBases
   * @param {number} offset
   */
  #make23_5mmSpacerGeometry(optionBases, offset) {
    const spacer23_5mm = optionBases.getObjectByName('235mm_Spacer');

    // @ts-ignore
    this.#spacer23_5mmGeo = spacer23_5mm.geometry.clone();
    this.#spacer23_5mmGeo.applyQuaternion(spacer23_5mm.quaternion);
    this.#spacer23_5mmGeo.translate(
      // Align to inside left of range top rim
      spacer23_5mm.position.x + offset - 2.743,
      spacer23_5mm.position.y,
      spacer23_5mm.position.z
    );
  }

  /**
   * Create a geometry for a 26mm wide spacer
   * @param {Group} optionBases
   * @param {number} offset
   */
  #make26mmSpacerGeometry(optionBases, offset) {
    const spacer26mm = optionBases.getObjectByName('26mm_Spacer');

    // @ts-ignore
    this.#spacer26mmGeo = spacer26mm.geometry.clone();
    this.#spacer26mmGeo.applyQuaternion(spacer26mm.quaternion);
    this.#spacer26mmGeo.translate(
      // Align to inside left of range top rim
      spacer26mm.position.x + offset - 1.092,
      spacer26mm.position.y,
      spacer26mm.position.z
    );
  }

  /**
   * Create a geometry for a 28mm wide spacer
   * @param {Group} optionBases
   * @param {number} offset
   */
  #make28mmSpacerGeometry(optionBases, offset) {
    const spacer28mm = optionBases.getObjectByName('28mm_Spacer');

    // @ts-ignore
    this.#spacer28mmGeo = spacer28mm.geometry.clone();
    this.#spacer28mmGeo.applyQuaternion(spacer28mm.quaternion);
    this.#spacer28mmGeo.translate(
      // Align to inside left of range top rim
      spacer28mm.position.x + offset - 2.7665,
      spacer28mm.position.y,
      spacer28mm.position.z
    );
  }
}

export { BaseSSRangeTop };
