import { Group, Object3D } from 'three';
import * as common from './common.js';
import { MeshName } from '../../shared/Enums';

/**
 * Various external (common) parts of the Sully range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Object3D[]}
 */
function parts(meshes) {
  // Mini Oven Vent Cover
  const leftOvenMiniVentCover = meshes
    .getObjectByName(MeshName.cupboardVentCover)
    .clone();
  leftOvenMiniVentCover.name = MeshName.leftOvenMiniVentCover;
  leftOvenMiniVentCover.position.x = -0.496;
  leftOvenMiniVentCover.visible = false;
  const rightOvenMiniVentCover = leftOvenMiniVentCover.clone();
  rightOvenMiniVentCover.name = MeshName.rightOvenMiniVentCover;
  rightOvenMiniVentCover.position.x = 0.497;
  rightOvenMiniVentCover.visible = false;

  return [
    leftOvenMiniVentCover,
    rightOvenMiniVentCover,
    ...common.sullyOvenDoorsAndDrawers(meshes),
  ];
}

/**
 * All the classique trim parts for a Sully range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function classiqueTrim(meshes) {
  const trim = new Group();

  // Towel Bar Supports
  const towelBarSupportLeft = meshes
    .getObjectByName('Towel_Bar_Support_Left')
    .clone();
  towelBarSupportLeft.position.x = -0.665;
  const towelBarSupportCenter = meshes
    .getObjectByName('Towel_Bar_Support_Center')
    .clone();
  const towelBarSupportRight = meshes
    .getObjectByName('Towel_Bar_Support_Right')
    .clone();
  towelBarSupportRight.position.x = 0.665;

  return trim.add(
    towelBarSupportLeft,
    towelBarSupportCenter,
    towelBarSupportRight,
    ...common.sullyOvenClassiqueTrim(meshes)
  );
}

/**
 * All the moderne trim parts for a Sully range
 * @param {Group} meshes - Common parts of most ranges
 * @returns {Group}
 */
function moderneTrim(meshes) {
  const trim = new Group();

  return trim.add(...common.sullyOvenModerneTrim(meshes));
}

export { classiqueTrim, moderneTrim, parts };
