import { Beaune } from './ranges/beaune/Beaune.js';
import { Chagny } from './ranges/chagny/Chagny.js';
import { Chambertin } from './ranges/chambertin/Chambertin.js';
import { Cluny1800 } from './ranges/cluny1800/Cluny1800.js';
import { Cormatin } from './ranges/cormatin/Cormatin.js';
import { Fontenay } from './ranges/fontenay/Fontenay.js';
import { Sully } from './ranges/sully/Sully.js';
import { Sully2200 } from './ranges/sully2200/Sully2200.js';
import { Sully1800Left } from './ranges/sully1800left/Sully1800Left.js';
import { Sully1800Right } from './ranges/sully1800right/Sully1800Right.js';
import { Volnay } from './ranges/volnay/Volnay.js';
import { BaseRange } from './ranges/shared/BaseRange.js';

import { Hood2400 } from './hoods/Hood2400.js';
import { Backsplash2200 } from './backsplashes/Backsplash2200.js';
import { KnobModels } from './ranges/shared/KnobModels.js';
import { RangeParts } from './ranges/parts/RangeParts.js';
import { RangeTopOptions } from './ranges/shared/RangeTopOptions.js';
import { AssetLoader } from './shared/AssetLoader.js';
import { loadEnvMap } from './shared/loadEnvMap.js';
import { Materials } from './shared/Materials.js';
import { SharedParts } from './shared/SharedParts.js';

import { createCamera } from './components/camera.js';
import { createLights } from './components/lights.js';
import { createScene } from './components/scene.js';

import { createControls } from './systems/controls.js';
import { createRenderer } from './systems/renderer.js';
import { HelperGUI } from './systems/HelperGUI.js';
import { Loop } from './systems/Loop.js';
import { Resizer } from './systems/Resizer.js';

class World {
  /** @type {Hood2400} */
  #hood;

  /** @type {AssetLoader} */
  #assets;

  /** @type {Materials} */
  #materials;

  /** @type {RangeTopOptions} */
  #rangeTopOptions;

  /** @type {KnobModels} */
  #knobModels;

  /** @type {RangeParts} */
  #rangeParts;

  /** @type {SharedParts} */
  #sharedParts;

  /** @type {BaseRange} */
  #range;

  #camera;
  #loop;
  #renderer;
  #scene;

  #showing = {
    hood: false,
    backsplash: false,
  };

  // @ts-ignore
  #gui;

  constructor(container) {
    this.#assets = new AssetLoader();
    this.#camera = createCamera();
    this.#renderer = createRenderer();
    this.#scene = createScene();
    this.#scene.position.y = -0.5;
    this.#loop = new Loop(this.#camera, this.#scene, this.#renderer);
    // this.#gui = new HelperGUI();
    // this.#gui.gui.hide();

    container.appendChild(this.#renderer.domElement);
    const controls = createControls(this.#camera, this.#renderer.domElement);

    const { hemisphereLight, leftLight, rightLight } = createLights();

    this.#loop.updatables.push(controls);

    this.#scene.add(hemisphereLight, leftLight, rightLight);

    // @ts-ignore
    const resizer = new Resizer(container, this.#camera, this.#renderer);

    // this.#gui.addHemiLight(hemisphereLight);
    // this.#gui.addLeftLight(leftLight);
    // this.#gui.addRightLight(rightLight);
  }

  async init() {
    await this.#assets.loadMinimum();

    this.#materials = new Materials(this.#assets);
    this.#rangeTopOptions = new RangeTopOptions(this.#assets, this.#materials);
    this.#knobModels = new KnobModels(this.#assets, this.#materials);
    this.#rangeParts = new RangeParts(this.#assets, this.#materials);
    this.#sharedParts = new SharedParts(this.#assets, this.#materials);

    // this.#range = this.#createSully1800Right();
    this.#range = this.#createSully();

    // this.#range.changeLine('moderne');
    this.#range.changeTrim('brass');
    this.#range.changeColor('burgundy-red');
    this.#range.changeRangeTop('classique', false);

    this.#scene.add(this.#range.range);

    this.initializeEventListeners();

    loadEnvMap(this.#scene);
  }

  initializeEventListeners() {
    document.getElementById('model').addEventListener('change', (e) => {
      // @ts-ignore
      const model = e.target.value;
      // @ts-ignore
      const line = document.getElementById('line').value;
      // @ts-ignore
      const top = document.getElementById('top').value;
      // @ts-ignore
      const trim = document.getElementById('trim').value;
      // @ts-ignore
      const color = document.getElementById('color').value;

      // Remove the old range from the scene
      this.#scene.remove(this.#range.range);

      switch (model) {
        case 'beaune':
          this.#range = this.#createBeaune();
          break;
        case 'chagny':
          this.#range = this.#createChagny();
          break;
        case 'chambertin':
          this.#range = this.#createChambertin();
          break;
        case 'cluny-1800':
          this.#range = this.#createCluny1800();
          break;
        case 'cormatin':
          this.#range = this.#createCormatin();
          break;
        case 'fontenay':
          this.#range = this.#createFontenay();
          break;
        case 'sully':
          this.#range = this.#createSully();
          break;
        case 'sully-1800D':
          this.#range = this.#createSully1800Right();
          break;
        case 'sully-1800G':
          this.#range = this.#createSully1800Left();
          break;
        case 'sully-2200':
          this.#range = this.#createSully2200();
          break;
        case 'volnay':
          this.#range = this.#createVolnay();
          break;
      }

      this.#range.changeLine(line);
      this.#range.changeRangeTop(top);
      this.#range.changeTrim(trim);
      this.#range.changeColor(color);

      // Add the new range to the scene
      this.#scene.add(this.#range.range);

      this.#showRangeOptions();
    });

    // Change the line
    document.getElementById('line').addEventListener('change', (e) => {
      // @ts-ignore
      const line = e.target.value;

      this.#range.changeLine(line);

      this.#updateTrimOptions(line);
    });

    // Change the base range top
    document.getElementById('top').addEventListener('change', (e) => {
      // @ts-ignore
      const top = e.target.value;
      const leftOptions = document.getElementById('left-option');
      // @ts-ignore
      const selectedOption = leftOptions.value;
      // @ts-ignore
      const options = leftOptions.options;

      this.#range.changeRangeTop(top);

      if (top === '4-feux') {
        // Enable the 18k burner, plancha and induction rings options
        options[3].disabled = false;
        options[4].disabled = false;
        options[5].disabled = false;
      } else {
        // Disable the 18k burner, plancha and induction rings options
        options[3].disabled = true;
        options[4].disabled = true;
        options[5].disabled = true;

        if (['LAG010UR', 'LAE010TPK', 'LAE020I'].includes(selectedOption)) {
          // Select stainless steel workstation
          options[6].selected = true;
          this.#range.changeOption2('SS');
        }
      }
    });

    // Change the trim
    document.getElementById('trim').addEventListener('change', (e) => {
      // @ts-ignore
      const trim = e.target.value;

      this.#range.changeTrim(trim);
    });

    // Change the color
    document.getElementById('color').addEventListener('change', (e) => {
      // @ts-ignore
      const color = e.target.value;

      this.#range.changeColor(color);
      this.#hood.changeColor(color);
    });

    // Change the far left range top option
    document
      .getElementById('far-left-option')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const farLeftOption = e.target.value;
        const leftCupboardOptions = // @ts-ignore
          document.getElementById('left-cupboard').options;

        this.#range.changeOption1(farLeftOption);

        // A multi-cooker needs to be over a storage cupboard
        if (farLeftOption === 'LAE010CVA') {
          leftCupboardOptions[1].selected = true;
          this.#range.changeLeftCupboard('storage');
        }
      });

    // Change the left range top option
    document.getElementById('left-option').addEventListener('change', (e) => {
      // @ts-ignore
      const leftOption = e.target.value;
      // @ts-ignore
      const model = document.getElementById('model').value;

      switch (model) {
        case 'cluny-1800':
        case 'sully-1800G':
        case 'sully-2200':
          this.#range.changeOption2(leftOption);
          break;

        case 'sully':
        case 'sully-1800D':
          this.#range.changeOption1(leftOption);
          break;
      }
    });

    // Change the right range top option
    document.getElementById('right-option').addEventListener('change', (e) => {
      // @ts-ignore
      const rightOption = e.target.value;
      // @ts-ignore
      const model = document.getElementById('model').value;

      switch (model) {
        case 'sully-1800G':
        case 'sully-2200':
          this.#range.changeOption3(rightOption);
          break;

        case 'sully':
        case 'sully-1800D':
          this.#range.changeOption2(rightOption);
          break;
      }
    });

    // Change the far right range top option
    document
      .getElementById('far-right-option')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const farRightOption = e.target.value;
        const rightCupboard = document.getElementById('right-cupboard');
        // @ts-ignore
        const model = document.getElementById('model').value;

        switch (model) {
          case 'chambertin':
            this.#range.changeOption1(farRightOption);
            break;
          case 'cluny-1800':
          case 'fontenay':
            this.#range.changeOption2(farRightOption);
            break;
          case 'sully-1800D':
            this.#range.changeOption3(farRightOption);
            break;
          case 'sully-2200':
            this.#range.changeOption4(farRightOption);
            break;
        }

        // A multi-cooker needs to be over a storage cupboard
        if (farRightOption === 'LAE010CVA') {
          // @ts-ignore
          rightCupboard.options[1].selected = true;
          this.#range.changeRightCupboard('storage');
        }
      });

    // Change the left cupboard
    document.getElementById('left-cupboard').addEventListener('change', (e) => {
      // @ts-ignore
      const leftCupboard = e.target.value;

      this.#range.changeLeftCupboard(leftCupboard);
    });

    // Change the right cupboard
    document
      .getElementById('right-cupboard')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const rightCupboard = e.target.value;

        this.#range.changeRightCupboard(rightCupboard);
      });
  }

  async init2() {
    await this.#assets.loadRemaining();

    this.#hood = new Hood2400(this.#assets, this.#materials);

    const backsplash = new Backsplash2200(this.#assets, this.#materials);

    // Show/Hide the hood
    const that = this; // TODO: Replace this with something more elegant
    document.getElementById('hood').addEventListener('change', function () {
      // @ts-ignore
      if (this.checked) {
        // Add the hood and zoom the camera out to show the range and hood
        that.#scene.add(that.#hood.hood);
        that.#camera.position.set(0, 1, 7);
        that.#scene.position.y = -1.4;
        that.#showing.hood = true;
        that.#showHoodOptions();
      } else if (that.#showing.backsplash) {
        // Remove the hood and zoom the camera in on the range and backsplash
        that.#scene.remove(that.#scene.getObjectByName(that.#hood.hood.name));
        that.#camera.position.set(0, 1, 5);
        that.#scene.position.y = -0.9;
        that.#showing.hood = false;
        that.#hideHoodOptions();
      } else {
        // Remove the hood and zoom the camera in on the range
        that.#scene.remove(that.#scene.getObjectByName(that.#hood.hood.name));
        that.#camera.position.set(0, 1.5, 4.5);
        that.#scene.position.y = -0.5;
        that.#showing.hood = false;
        that.#hideHoodOptions();
      }
    });

    // Change the hood insert
    document.getElementById('hood-insert').addEventListener('change', (e) => {
      // @ts-ignore
      this.#hood.changeInsert(e.target.value);
    });

    // Change the hood duct cover finish
    document
      .getElementById('duct-cover-finish')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const ductCoverFinish = e.target.value;

        this.#hood.changeDuctCoverFinish(ductCoverFinish);
      });

    // Change the hood duct cover height
    document
      .getElementById('duct-cover-height')
      .addEventListener('change', (e) => {
        // @ts-ignore
        const ductCoverHeight = e.target.value;

        this.#hood.changeDuctCoverHeight(ductCoverHeight);
      });

    // Show/Hide the backsplash
    document
      .getElementById('backsplash')
      .addEventListener('change', function () {
        // @ts-ignore
        if (this.checked) {
          // Add the backsplash
          that.#scene.add(backsplash.backsplash);
          that.#showing.backsplash = true;
          if (!that.#showing.hood) {
            // Zoom the camera out to show the range and backsplash
            that.#camera.position.set(0, 1, 5);
            that.#scene.position.y = -0.9;
          }
        } else {
          // Remove the backsplash
          that.#scene.remove(
            that.#scene.getObjectByName(backsplash.backsplash.name)
          );
          that.#showing.backsplash = false;
          if (!that.#showing.hood) {
            // Zoom the camera in on the range
            that.#camera.position.set(0, 1.5, 4.5);
            that.#scene.position.y = -0.5;
          }
        }
      });
  }

  render() {
    this.#renderer.render(this.#scene, this.#camera);
  }

  start() {
    this.#loop.start();
  }

  stop() {
    this.#loop.stop();
  }

  /**
   * Create a Beaune range
   * @returns {Beaune}
   */
  #createBeaune() {
    return new Beaune(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Chagny range
   * @returns {Chagny}
   */
  #createChagny() {
    return new Chagny(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Chambertin range
   * @returns {Chambertin}
   */
  #createChambertin() {
    return new Chambertin(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Cluny 1800 range
   * @returns {Cluny1800}
   */
  #createCluny1800() {
    return new Cluny1800(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Cormatin range
   * @returns {Cormatin}
   */
  #createCormatin() {
    return new Cormatin(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Fontenay range
   * @returns {Fontenay}
   */
  #createFontenay() {
    return new Fontenay(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Sully range
   * @returns {Sully}
   */
  #createSully() {
    return new Sully(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Sully 1800 (left) range
   * @returns {Sully1800Left}
   */
  #createSully1800Left() {
    return new Sully1800Left(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Sully 1800 (right) range
   * @returns {Sully1800Right}
   */
  #createSully1800Right() {
    return new Sully1800Right(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Sully 2200 range
   * @returns {Sully2200}
   */
  #createSully2200() {
    return new Sully2200(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Create a Volnay range
   * @returns {Volnay}
   */
  #createVolnay() {
    return new Volnay(
      this.#assets,
      this.#materials,
      this.#rangeParts,
      this.#sharedParts,
      this.#rangeTopOptions,
      this.#knobModels
    );
  }

  /**
   * Disable the options of brass and nickel for moderne ranges
   * @param {string} line - classique or moderne
   */
  #updateTrimOptions(line) {
    const trimSelect = document.getElementById('trim');
    // @ts-ignore
    const trim = trimSelect.value;
    // @ts-ignore
    const options = trimSelect.options;

    if (line === 'moderne') {
      // Disable the brass and nickel options
      options[0].disabled = true;
      options[3].disabled = true;

      if (!['brushedSS', 'chrome'].includes(trim)) {
        // Select chrome
        options[2].selected = true;
      }
    } else if (line === 'classique') {
      // Enable the brass and nickel options
      options[0].disabled = false;
      options[3].disabled = false;
    }
  }

  /**
   * Hide or show the optional burner choices and cupboard choices depending on
   * what a range has. Disable the 4 feux when not supported
   */
  #showRangeOptions() {
    // @ts-ignore
    const rangeTopOptions = document.getElementById('top').options;
    const farLeftOptionBlock = document.getElementById('far-left-option-block');
    const leftOptionBlock = document.getElementById('left-option-block');
    const rightOptionBlock = document.getElementById('right-option-block');
    const farRightOptionBlock = document.getElementById(
      'far-right-option-block'
    );
    const leftCupboardBlock = document.getElementById('left-cupboard-block');
    const rightCupboardBlock = document.getElementById('right-cupboard-block');

    farLeftOptionBlock.style.display = this.#range.features.farLeftOption
      ? 'block'
      : 'none';
    leftOptionBlock.style.display = this.#range.features.leftOption
      ? 'block'
      : 'none';
    rightOptionBlock.style.display = this.#range.features.rightOption
      ? 'block'
      : 'none';
    farRightOptionBlock.style.display = this.#range.features.farRightOption
      ? 'block'
      : 'none';
    leftCupboardBlock.style.display = this.#range.features.leftCupboard
      ? 'block'
      : 'none';
    rightCupboardBlock.style.display = this.#range.features.rightCupboard
      ? 'block'
      : 'none';
    rangeTopOptions[1].style.display = this.#range.features.traditional
      ? 'block'
      : 'none';
    rangeTopOptions[2].style.display = this.#range.features.fourFeux
      ? 'block'
      : 'none';
    rangeTopOptions[3].style.display = this.#range.features.classiqueRev
      ? 'block'
      : 'none';
  }

  #showHoodOptions() {
    const hoodInsertDiv = document.getElementById('hood-insert-div');
    hoodInsertDiv.style.display = 'block';

    const ductCoverFinishDiv = document.getElementById('duct-cover-finish-div');
    ductCoverFinishDiv.style.display = 'block';

    const ductCoverHeightDiv = document.getElementById('duct-cover-height-div');
    ductCoverHeightDiv.style.display = 'block';
  }

  #hideHoodOptions() {
    const hoodInsertDiv = document.getElementById('hood-insert-div');
    hoodInsertDiv.style.display = 'none';

    const ductCoverFinishDiv = document.getElementById('duct-cover-finish-div');
    ductCoverFinishDiv.style.display = 'none';

    const ductCoverHeightDiv = document.getElementById('duct-cover-height-div');
    ductCoverHeightDiv.style.display = 'none';
  }
}

export { World };
