import { Group, Mesh, Object3D } from 'three';

import { BaseKnobs } from './BaseKnobs.js';
import { RangeParts } from '../parts/RangeParts.js';
import { RangeTopOptions } from '../shared/RangeTopOptions.js';

import { AssetLoader } from '../../shared/AssetLoader.js';
import {
  Burner,
  Line,
  MeshName,
  OptionSpace,
  Region,
  Trim,
} from '../../shared/Enums.js';
import { Materials } from '../../shared/Materials.js';
import { State } from '../../shared/State.js';

class BaseRange {
  /** All range meshes should be added to (or removed from) this group */
  range = new Group();

  /**
   * The 3D models for the range
   * @type {AssetLoader}
   */
  assets;

  /**
   * Applies various materials to various parts of the range
   * @type {Materials}
   */
  materials;

  /**
   * Parts of the range that are common to all models
   * @type {RangeParts}
   */
  rangeParts;

  /**
   * The optional burners including the flame grill, plancha, multi-cooker, and
   * traditional simmer plate
   * @type {RangeTopOptions}
   */
  rangeTopOptions;

  /**
   * The classique trim for the selected range
   * @type {Group}
   */
  classiqueTrim;

  /**
   * The moderne trim for the selected range
   * @type {Group}
   */
  moderneTrim;

  /** The core 3 or 4 burners in the middle of the range */
  baseBurners = new Group();

  /**
   * Controls the placement of the knobs on the Control Panel
   * @type {BaseKnobs}
   */
  knobs;

  /** The current configuration of the range */
  state = new State();

  /**
   * @typedef Features
   * @type {object}
   * @property {boolean} farLeftOption - Show a selection of far left (over cupboard) burner options
   * @property {boolean} leftOption - Show a selection of left burner options
   * @property {boolean} rightOption - Show a selection of left burner options
   * @property {boolean} farRightOption - Show a selection of far right (over cupboard) burner options
   * @property {boolean} leftCupboard - Show a selection of left cupboard options
   * @property {boolean} rightCupboard - Show a selection of right cupboard options
   * @property {boolean} traditional - Enable the traditional option for range tops
   * @property {boolean} fourFeux - Enable the 4-feux option for range tops
   * @property {boolean} classiqueRev - Enable the classique reverse option for range tops
   */

  /**
   * The UI features that are available
   * @type {Features}
   */
  features = {
    farLeftOption: false,
    leftOption: false,
    rightOption: false,
    farRightOption: false,
    leftCupboard: false,
    rightCupboard: false,
    traditional: true,
    fourFeux: false,
    classiqueRev: false,
  };

  /**
   * @param {AssetLoader} assets
   * @param {Materials} materials
   * @param {RangeParts} rangeParts
   * @param {RangeTopOptions} rangeTopOptions
   */
  constructor(assets, materials, rangeParts, rangeTopOptions) {
    this.assets = assets;
    this.materials = materials;
    this.rangeParts = rangeParts;
    this.rangeTopOptions = rangeTopOptions;
  }

  /**
   * Change the line of the range to classique or moderne
   * @param {string} line
   */
  changeLine(line) {
    this.state.line = line;
    this.changeControlPanelAndSide(line);
    this.knobs.changeLine();

    if (line === Line.moderne) {
      this.range.remove(this.classiqueTrim);
      this.range.add(this.moderneTrim);

      if (![Trim.brushedSS, Trim.chrome].includes(this.state.trim)) {
        this.changeTrim(Trim.chrome);
      }
    } else if (line === Line.classique) {
      this.range.remove(this.moderneTrim);
      this.range.add(this.classiqueTrim);
    }
  }

  /**
   * Change the trim of the range to brass, brushed stainless steel, chrome, or nickel
   * @param {string} trim
   */
  changeTrim(trim) {
    this.state.trim = trim;
    this.materials.changeTrim(trim, this.trimParts());
    this.knobs.changeTrim(trim);
  }

  /**
   * Change the color of the porcelain enamel panels on the range
   * @param {string} color
   */
  changeColor(color) {
    this.state.color = color;
    this.materials.changeColor(color, this.colorParts());
    this.knobs.changeKnobDialColor();

    if (this.state.line !== Line.moderne) {
      this.materials.changeColor(color, [
        this.range.getObjectByName(MeshName.controlPanel),
        this.range.getObjectByName(MeshName.rangeSides),
      ]);
    }
  }

  /**
   * Change the first optional "burner" of the range top
   * @param {string} burnerOption
   * @param {boolean} [assembleTop]
   */
  changeOption1(burnerOption, assembleTop = true) {
    this.changeBurner(burnerOption, OptionSpace.option1, assembleTop);
  }

  /**
   * Change the optional "burner" in the left position of the range top
   * @param {string} burnerOption
   * @param {boolean} [assembleTop]
   */
  changeOption2(burnerOption, assembleTop = true) {
    this.changeBurner(burnerOption, OptionSpace.option2, assembleTop);
  }

  /**
   * Change the optional "burner" in the right position of the range top
   * @param {string} burnerOption
   * @param {boolean} [assembleTop]
   */
  changeOption3(burnerOption, assembleTop = true) {
    this.changeBurner(burnerOption, OptionSpace.option3, assembleTop);
  }

  /**
   * Change the optional "burner" in the far right position of the range top
   * @param {string} burnerOption
   * @param {boolean} [assembleTop]
   */
  changeOption4(burnerOption, assembleTop = true) {
    this.changeBurner(burnerOption, OptionSpace.option4, assembleTop);
  }

  /**
   * Add a two burner (11k front, 5k rear) option to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  add11And5KBurners(xOffset, group) {
    const elevenAnd5KBurners = this.rangeTopOptions.elevenAnd5KBurners.clone();
    elevenAnd5KBurners.translateX(xOffset);

    group.add(elevenAnd5KBurners);
  }

  /**
   * Add a two burner (5k front, 11k rear) option to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  add5And11KBurners(xOffset, group) {
    const fiveAnd11KBurners = this.rangeTopOptions.fiveAnd11KBurners.clone();
    fiveAnd11KBurners.translateX(xOffset);

    group.add(fiveAnd11KBurners);
  }

  /**
   * Add a two burner (15k front, 5k rear) option to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  add15And5KBurners(xOffset, group) {
    const fifteenAnd5KBurners =
      this.rangeTopOptions.fifteenAnd5KBurners.clone();
    fifteenAnd5KBurners.translateX(xOffset);

    group.add(fifteenAnd5KBurners);
  }

  /**
   * Add a two burner (15k front, 11k rear) option to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  add15And11KBurners(xOffset, group) {
    const fifteenAnd11KBurners =
      this.rangeTopOptions.fifteenAnd11KBurners.clone();
    fifteenAnd11KBurners.translateX(xOffset);

    group.add(fifteenAnd11KBurners);
  }

  /**
   * Add a two 11k burners option to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  add11KBurners(xOffset, group) {
    const elevenKBurners = this.rangeTopOptions.elevenKBurners.clone();
    elevenKBurners.translateX(xOffset);

    group.add(elevenKBurners);
  }

  /**
   * Add a two 15k burners option to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  add15KBurners(xOffset, group) {
    const fifteenKBurners = this.rangeTopOptions.fifteenKBurners.clone();
    fifteenKBurners.translateX(xOffset);

    group.add(fifteenKBurners);
  }

  /**
   * Add an 18k (classique) burner option to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  add18KBurner(xOffset, group) {
    const eighteenKBurner = this.rangeTopOptions.eighteenKBurner.clone();
    eighteenKBurner.translateX(xOffset);

    group.add(eighteenKBurner);
  }

  /**
   * Add two spacer tabs for the left side of a two burner grate
   * @param {number} xOffset
   * @param {Group} group
   */
  addGrateLeftSpacerTabs(xOffset, group) {
    const spacer = this.rangeTopOptions.leftGrateSpacerTabs.clone();
    spacer.translateX(xOffset);

    group.add(spacer);
  }

  /**
   * Add two spacers for the right side of a two burner grate
   * @param {number} xOffset
   * @param {Group} group
   */
  addGrateRightSpacerTabs(xOffset, group) {
    const spacer = this.rangeTopOptions.rightGrateSpacerTabs.clone();
    spacer.translateX(xOffset);

    group.add(spacer);
  }

  /**
   * Add intermediate spacer grate used in most 4-feux configurations
   * @param {number} xOffset
   * @param {Group} group
   */
  add4FeuxSpacerGrate(xOffset, group) {
    const spacer = this.rangeTopOptions.fourFeuxSpacerGrate.clone();
    spacer.translateX(xOffset);

    group.add(spacer);
  }

  /**
   * Add side spacer tabs to separate two burner grates used in a Sully 4-feux
   * @param {number} xOffset
   * @param {Group} group
   */
  addSully4FeuxGrateSpacerTabs(xOffset, group) {
    const spacer = this.rangeTopOptions.fourFeuxSpacerTabs.clone();
    spacer.translateX(xOffset);

    group.add(spacer);
  }

  /**
   * Add a traditional simmer plate to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  addTradPlate(xOffset, group) {
    const tradPlateGroup = this.rangeTopOptions.tradPlateGroup.clone();
    tradPlateGroup.translateX(xOffset);

    group.add(tradPlateGroup);
  }

  /**
   * Add a multi-cooker to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  addMultiCooker(xOffset, group) {
    const multiCooker = this.rangeTopOptions.multiCooker.clone();
    multiCooker.translateX(xOffset);

    group.add(multiCooker);
  }

  /**
   * Add an electric plancha to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  addPlancha(xOffset, group) {
    const plancha = this.rangeTopOptions.plancha.clone();
    plancha.translateX(xOffset);

    group.add(plancha);
  }

  /**
   * Add a flame grill to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  addFlameGrill(xOffset, group) {
    const flameGrill = this.rangeTopOptions.flameGrill.clone();
    flameGrill.translateX(xOffset);

    group.add(flameGrill);
  }

  /**
   * Add a two induction rings option to the range top
   * @param {number} xOffset
   * @param {Group} group
   */
  addInductionRings(xOffset, group) {
    const inductionRings = this.rangeTopOptions.inductionRings.clone();
    inductionRings.translateX(xOffset);

    group.add(inductionRings);
  }

  /**
   * For moderne, change the control panel and sides to stainless steel
   * @param {string} line
   */
  changeControlPanelAndSide(line) {
    if (line === Line.moderne) {
      this.materials.applyStainlessSteelMaterial(
        this.range.getObjectByName(MeshName.controlPanel),
        this.range.getObjectByName(MeshName.rangeSides)
      );
    } else if (line === Line.classique) {
      this.materials.changeColor(this.state.color, [
        this.range.getObjectByName(MeshName.controlPanel),
        this.range.getObjectByName(MeshName.rangeSides),
      ]);
    }
  }

  /** Apply the stainless steel and galvanized steel materials to the corresponding meshes */
  applyMaterials() {
    this.materials.applyStainlessSteelMaterial(...this.stainlessSteelParts());

    this.materials.applyGalvanizedSteelMaterial(...this.galvanizedSteelParts());
  }

  showCorrectOvenVent(burnerOption, region) {
    const showMiniVent = burnerOption === Burner.electricPlancha;

    switch (region) {
      case Region.left:
        this.range.getObjectByName(MeshName.leftOvenVentCover).visible =
          !showMiniVent;
        this.range.getObjectByName(MeshName.leftOvenMiniVentCover).visible =
          showMiniVent;
        break;

      case Region.right:
        this.range.getObjectByName(MeshName.rightOvenVentCover).visible =
          !showMiniVent;
        this.range.getObjectByName(MeshName.rightOvenMiniVentCover).visible =
          showMiniVent;
        break;
    }
  }

  /**
   * Change the range top style to classique, traditional, or 4-feux
   * @abstract
   * @param {string} rangeTop
   * @param {boolean} [assembleTop] Whether to assemble a new top and add it to the range
   */
  changeRangeTop(rangeTop, assembleTop = true) {
    throw new Error('This method must be implemented by subclass!');
  }

  /**
   * Change the one of the optional "burners" on the range top
   * @abstract
   * @param {string} burnerOption
   * @param {string} optionSpace
   * @param {boolean} [assembleTop]
   */
  changeBurner(burnerOption, optionSpace, assembleTop) {
    throw new Error('This method must be implemented by subclass!');
  }

  /**
   * Change the left cupboard to warming or storage
   * @abstract
   * @param {string} type
   */
  changeLeftCupboard(type) {
    throw new Error('This method must be implemented by subclass!');
  }

  /**
   * Change the right cupboard to warming or storage
   * @abstract
   * @param {string} type
   */
  changeRightCupboard(type) {
    throw new Error('This method must be implemented by subclass!');
  }

  /**
   * All the trim parts of the range that can be brass, brushed stainless steel, chrome, or nickel
   * @abstract
   * @returns {Object3D[]}
   */
  trimParts() {
    return [];
  }

  /**
   * All the parts of the range that are porcelain enamel
   * @abstract
   * @returns {Object3D[]}
   */
  colorParts() {
    return [];
  }

  /**
   * All the parts of the range that are stainless steel
   * @abstract
   * @returns {Object3D[]}
   */
  stainlessSteelParts() {
    return [];
  }

  /**
   * All the parts of the range that are galvanized steel
   * @abstract
   * @returns {Object3D[]}
   */
  galvanizedSteelParts() {
    return [];
  }
}

export { BaseRange };
