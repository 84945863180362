import { BufferGeometry, Group, Mesh, Object3D } from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';

import { BaseSSRangeTop } from '../shared/BaseSSRangeTop';
import { RangeTopOptions } from '../shared/RangeTopOptions';

import { Burner, MeshName } from '../../shared/Enums';

class SSRangeTop extends BaseSSRangeTop {
  /**
   * An outer rim geometry for a Sully 2200 range top
   * @type {BufferGeometry}
   */
  #topRimGeo;

  /** @type {Mesh} */
  #topRimMesh;

  /**
   * @typedef TopState
   * @type {object}
   * @property {boolean} [fourFeux] - is the range top 4-feux
   * @property {string} [option1] - the first (leftmost) range top option
   * @property {string} [option2] - the second range top option
   * @property {string} [option3] - the third range top option
   * @property {string} [option4] - the fourth (rightmost) range top option
   */

  /**
   * Configured range top options
   * @type {TopState}
   */
  #state = {};

  /**
   * @param {Group} range
   * @param {RangeTopOptions} rangeTopOptions
   */
  constructor(range, rangeTopOptions) {
    super(rangeTopOptions, 0);

    this.#makeTopRimGeometry(range);
  }

  /**
   * The fully configured stainless steel range top - based on range top options
   * @param {TopState} newState
   * @returns {Mesh}
   */
  assembleSSRangeTop(newState) {
    this.#state = { ...this.#state, ...newState };

    const geometries = [
      this.#topRimGeo,
      this.#option1Surface(),
      this.#spacer1to2(),
      this.#option2Surface(),
      this.#spacerOption2toBase(),
      this.#leftBaseSurface(),
      this.#spacerBaseToOption3(),
      this.#option3Surface(),
      this.#spacer3to4(),
      this.#option4Surface(),
    ];
    if (!this.#state.fourFeux) {
      geometries.push(this.#midBaseSpacer(), this.#rightBaseSurface());
    }

    const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries);

    const rangeTop = new Mesh(mergedGeometry, this.#topRimMesh.material);
    rangeTop.name = 'range-top';

    return rangeTop;
  }

  /**
   * Create a geometry for the outer rim of the range top
   * @param {Group} range
   */
  #makeTopRimGeometry(range) {
    // @ts-ignore
    this.#topRimMesh = range.getObjectByName(MeshName.rangeTopRim2200);

    this.#topRimGeo = this.#topRimMesh.geometry.clone();
    this.#topRimGeo.applyQuaternion(this.#topRimMesh.quaternion);
    this.#topRimGeo.translate(
      this.#topRimMesh.position.x,
      this.#topRimMesh.position.y,
      this.#topRimMesh.position.z
    );
  }

  /**
   * A range top surface geometry for the first range top option
   * @returns {BufferGeometry}
   */
  #option1Surface() {
    const offset = 0;

    switch (this.#state.option1) {
      case Burner.two11kBurners:
      case Burner.two15kBurners:
        return this.wide2BurnerPond(offset);

      case Burner.one18kBurner:
      case Burner.traditionalSimmerPlate:
      case Burner.multiCooker:
        return this.oneBurnerPond(offset);

      case Burner.flameGrill:
        return this.flameGrillPond(offset);

      default:
        return this.wideWorkstation(offset);
    }
  }

  /**
   * A range top spacer geometry between the first and second range top options
   * @returns {BufferGeometry}
   */
  #spacer1to2() {
    const offset = 0.39;

    if (this.#state.fourFeux) {
      return this.spacer13mm(offset);
    } else {
      return this.spacer26mm(offset);
    }
  }

  /**
   * A range top surface geometry for the second range top option
   * @returns {BufferGeometry}
   */
  #option2Surface() {
    let offset = 0.403;

    if (this.#state.fourFeux) {
      // More space to the left of the 4 feux burners
      switch (this.#state.option2) {
        case Burner.two11kBurners:
        case Burner.two15kBurners:
          return this.wide2BurnerPond(offset);

        case Burner.one18kBurner:
          return this.oneBurnerPond(offset);

        default:
          return this.wideWorkstation(offset);
      }
    } else {
      offset += 0.013;
      // Narrow space for optional burners
      switch (this.#state.option2) {
        case Burner.two11kBurners:
        case Burner.two15kBurners:
          return this.narrow2BurnerPond(offset);

        default:
          return this.narrowWorkstation(offset);
      }
    }
  }

  /**
   * A range top spacer geometry between the second range top option and the
   * base burners
   * @returns {BufferGeometry}
   */
  #spacerOption2toBase() {
    if (this.#state.fourFeux) {
      return this.spacer13mm(0.793);
    } else {
      return this.spacer26mm(0.676);
    }
  }

  /**
   * A range top surface geometry for the left "half" of the base burners
   * @returns {BufferGeometry}
   */
  #leftBaseSurface() {
    if (this.#state.fourFeux) {
      return this.sully4BurnerPond(0.806);
    } else {
      return this.oneBurnerPond(0.702);
    }
  }

  /**
   * A range top spacer geometry between the two halves of the base burners
   * @returns {BufferGeometry}
   */
  #midBaseSpacer() {
    return this.spacer26mm(1.092);
  }

  /**
   * A range top surface geometry for the right "half" of the base burners
   * @returns {BufferGeometry}
   */
  #rightBaseSurface() {
    return this.narrow2BurnerPond(1.118);
  }

  /**
   * A range top spacer geometry between the base burners and the third range
   * top option
   * @returns {BufferGeometry}
   */
  #spacerBaseToOption3() {
    return this.spacer13mm(1.378);
  }

  /**
   * A range top surface geometry for the third range top option
   * @returns {BufferGeometry}
   */
  #option3Surface() {
    const offset = 1.391;

    switch (this.#state.option3) {
      case Burner.two11kBurners:
      case Burner.two15kBurners:
        return this.wide2BurnerPond(offset);

      case Burner.one18kBurner:
      case Burner.traditionalSimmerPlate:
        return this.oneBurnerPond(offset);

      default:
        return this.wideWorkstation(offset);
    }
  }

  /**
   * A range top spacer geometry between the third and fourth range top options
   * @returns {BufferGeometry}
   */
  #spacer3to4() {
    return this.spacer13mm(1.781);
  }

  /**
   * A range top surface geometry for the fourth range top option
   * @returns {BufferGeometry}
   */
  #option4Surface() {
    const offset = 1.794;

    switch (this.#state.option4) {
      case Burner.two11kBurners:
      case Burner.two15kBurners:
        return this.wide2BurnerPond(offset);

      case Burner.one18kBurner:
      case Burner.traditionalSimmerPlate:
      case Burner.multiCooker:
        return this.oneBurnerPond(offset);

      case Burner.flameGrill:
        return this.flameGrillPond(offset);

      default:
        return this.wideWorkstation(offset);
    }
  }
}

export { SSRangeTop };
